import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button";
import { useMutation, useReactiveVar } from "@apollo/client";
import { UPDATE_PASSWORD_MUTATION } from "../../graphql";
import { useState } from "react";
import { AuthComponentProps } from "../types";
import { useNavigateWithState } from "../../../../utils/useNavigateWithState";
import { STEP_URLS } from "../../../../constants";
import { storeUserProfileData } from "../../../../utils";
import { CommonPasswords } from "./CommonPasswords";
import { authTokenVar } from "../../../../apollo/state";

export function ChangePassword({ sheetId }: AuthComponentProps) {
  const { t } = useTranslation();
  const navigate = useNavigateWithState();
  const authToken = useReactiveVar(authTokenVar);
  const [password, setPassword] = useState("");
  const [isMutationFailed, setIsMutationFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [doSignup] = useMutation(UPDATE_PASSWORD_MUTATION, {
    variables: {
      updatePasswordArgs: {
        sheetId,
        authToken,
        newPassword: password,
      },
    },
  });

  async function handleOKClick() {
    setIsLoading(true);
    try {
      const {
        data: {
          updatePassword: { token, email },
        },
      } = await doSignup();
      storeUserProfileData(email, token);
      setIsMutationFailed(false);
      navigate(STEP_URLS.start);
    } catch (e) {
      debugger;
      setIsMutationFailed(true);
    }
    setIsLoading(false);
  }

  return (
    <CommonPasswords
      errorMessage={t("wizard.welcome.passwordChangeFailed", "Password change failed")}
      isMutationFailed={isMutationFailed}
      onEnterPressed={handleOKClick}
      onValueChanged={value => {
        setPassword(value);
        setIsMutationFailed(false);
      }}
    >
      {({ shouldDisableControls }) => (
        <>
          <Button onClick={handleOKClick} disabled={isLoading || shouldDisableControls}>
            {t("wizard.welcome.authOK", "OK")}
          </Button>
          <Button m="0 0 0 50px" onClick={() => navigate(STEP_URLS.start)} disabled={isLoading}>
            {t("wizard.welcome.authCancel", "Cancel")}
          </Button>
        </>
      )}
    </CommonPasswords>
  );
}
