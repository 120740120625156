import { makeVar } from "@apollo/client";
import { SelectionMap, HorizonMode } from "../@types";
import { HORIZON_MODE } from "../constants";

export type AppState = {
  sheetId?: string;
  localeId?: string;
  selectedPreset: string;
  selectedKpis: SelectionMap;
  selectedMeasures: SelectionMap;
  selectedHorizon: HorizonMode;
  showOnlyImportantConnections: boolean;
};

export type FieldsActivationState = {
  partiallyActivatedFieldIdsMap: SelectionMap;
  fullyActivatedFieldIdsMap: SelectionMap;
};

export const selectedPresetVar = makeVar<string>("");
export const selectedPresetNameVar = makeVar<string>("");
export const selectedKpisVar = makeVar<SelectionMap>({});
export const selectedMeasuresVar = makeVar<SelectionMap>({});
export const selectedHorizonVar = makeVar<HorizonMode>(HORIZON_MODE.short as HorizonMode);
export const showOnlyImportantConnectionsVar = makeVar<boolean>(false);
export const lastIntroStepVar = makeVar<string>("");
export const authTokenVar = makeVar<string | null>(null);
export const userEmailVar = makeVar<string | null>(null);

export const fieldsActivationStateVar = makeVar<FieldsActivationState>({
  partiallyActivatedFieldIdsMap: {},
  fullyActivatedFieldIdsMap: {},
});
