import { useTranslation } from "react-i18next";
import fileDialog from "file-dialog";
import Box from "../../../components/Box";
import Button from "../../../components/Button";
import Typography from "../../../components/Typography";
import { STEP_URLS } from "../../../constants";
import { UnderlinedButton } from "./styled";
import { INTRO_STEP } from "../../../constants/routing";
import { AppState } from "../../../apollo/state";
import { useNavigateWithState } from "../../../utils/useNavigateWithState";
import qs from "qs";
import { useSettings } from "../../../context/Settings";
import { useNavigate } from "react-router";
import LoginWelcome from "./components/LoginWelcome";

function Welcome() {
  const navigate = useNavigateWithState();
  const plainNavigate = useNavigate();
  const { t } = useTranslation();
  const { demoMode } = useSettings();

  function handleStartClick() {
    navigate(STEP_URLS.weighting);
  }

  function handleIntroClick() {
    navigate(STEP_URLS.intro.replace(INTRO_STEP, "1"));
  }

  function handleLoadClick() {
    fileDialog({ accept: [".esg"] }).then(files => {
      const reader = new FileReader();
      reader.readAsText(files[0]);
      reader.onload = () => {
        const config = JSON.parse(reader.result as string) as AppState;
        const urlParams = qs.stringify(config);
        plainNavigate(`${STEP_URLS.configLoader}?${urlParams}`);
        window.location.reload();
      };
    });
  }

  return (
    <LoginWelcome hidePreset={false}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "20px",
        }}
      >
        <div>
          <Button onClick={handleIntroClick}>START</Button>
        </div>
        {!demoMode && (
          <>
            <div>
              <Button onClick={handleLoadClick}>{t("wizard.welcome.load", "LOAD")}</Button>
            </div>
            <Typography size="15px">
              <UnderlinedButton onClick={handleStartClick}>START</UnderlinedButton>{" "}
              {t("wizard.welcome.withoutIntro", "WITHOUT INTRO")}
            </Typography>
            <Typography size="14px" nowrap>
              {t("wizard.welcome.openFile", "Open the saved configuration file")}
            </Typography>
          </>
        )}
      </Box>
    </LoginWelcome>
  );
}

export default Welcome;
