import styled from "styled-components";
import Box from "../Box";

export const StyledStepWrapper = styled(Box)`
  position: absolute;
  z-index: 1;
  width: 40%;
  right: 0;
  height: 100%;
  min-height: 768px;
  background: var(--white);
  box-shadow: var(--box-shadow);
  overflow: auto;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;

  @media (min-width: 1366px) {
    padding: 30px 40px;
  }
`;

export const PresetWrapper = styled(Box)`
  position: absolute;
  margin-top: -30px;
  right: 0px;
  border-left: 2px solid #777;
  border-bottom: 2px solid #777;
  border-radius: 0px 0px 0px 15px;
  padding-left: 15px;
  padding-bottom: 7px;
  padding-top: 5px;
  padding-right: 30px;
`;
