import { ChangeEvent } from "react";
import {
  Attribute,
  AttributesBox,
  Container,
  DeselectButton,
  DeselectButtonContainer,
  Header,
  Name,
  Relevance,
  SubFields,
  TitleWrapper,
} from "./styled";
import { FieldAttributesProps } from "./types";
import { Accordion, AccordionDetail, AccordionSummary } from "../Accordion";
import Checkbox from "../Checkbox";
import Typography from "../Typography";
import Box from "../Box";
import { useTranslation } from "react-i18next";
import Tag from "../Tag";
import { getFieldIdFromSubFieldId } from "../../routes/RadarWizard/utils";
import { ExclamationIcon } from "../../routes/RadarWizard/PickKeysForSubField/icons";

const MAX_ATTRIBUTES_PER_FIELD = 2;

export function FieldAttributes({
  items,
  onChange,
  title,
  fieldsMap,
  subFieldsMap,
  onSubFieldClicked,
  currentSubField,
  clearSelection,
}: FieldAttributesProps) {
  const { t } = useTranslation();

  const selectedAttributesForCurrentFieldCount = items.filter(item => item.isSelected).length;

  return (
    <Container>
      <AttributesBox>
        <TitleWrapper>
          <Typography size="16px" bold>
            {title}
          </Typography>
        </TitleWrapper>
        <DeselectButtonContainer>
          <DeselectButton
            onClick={clearSelection}
            disabled={!selectedAttributesForCurrentFieldCount}
          >
            {t("wizard.pickKeysForSubField.deleteSelection", "Delete Selection")}
          </DeselectButton>
        </DeselectButtonContainer>
        <Header>
          <Name />
          <Relevance>
            <Typography bold>{t("wizard.pickKeysForSubField.relevance", "Relevance")}</Typography>
          </Relevance>
          <SubFields>
            <Typography bold>
              {t("wizard.pickKeysForSubField.affectsAlso", "Affects also")}
            </Typography>
          </SubFields>
        </Header>
        {items.map(item => (
          <Attribute key={item.id}>
            <Name>
              <Accordion>
                <AccordionSummary>
                  <label>
                    <Checkbox
                      checked={item.isSelected}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onChange(item.id, e.currentTarget.checked);
                      }}
                    />
                  </label>
                  <Typography as="span" sx={{ cursor: "pointer" }}>
                    {item.shortName}
                  </Typography>
                </AccordionSummary>
                <AccordionDetail>
                  <Typography size="13px" m="5px 0 0 35px" lh="16px">
                    <Typography bold m="0 0 5px 0">
                      {item.name}
                    </Typography>
                    <Box
                      sx={{
                        wordWrap: "pre-line",
                        whiteSpace: "break-spaces",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    />
                  </Typography>
                </AccordionDetail>
              </Accordion>
            </Name>
            <Relevance>{item.relevance}%</Relevance>
            <SubFields>
              {item.subFields
                .filter(id => id !== currentSubField.id)
                .map(id => {
                  const fieldId = getFieldIdFromSubFieldId(id);

                  return (
                    <Tag
                      {...fieldsMap[fieldId]}
                      {...subFieldsMap[id]}
                      key={id}
                      pointer
                      onClick={() => onSubFieldClicked(id)}
                    />
                  );
                })}
            </SubFields>
          </Attribute>
        ))}
      </AttributesBox>
      {selectedAttributesForCurrentFieldCount > MAX_ATTRIBUTES_PER_FIELD && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            width: "100%",
            margin: "0 0 -10px",
            padding: "10px 0 0",
            background: "var(--white)",
          }}
        >
          <ExclamationIcon />
          <Typography size="13px">
            {t(
              "wizard.pickKeysForSubField.attributesFocusRecommended",
              "A focus on 1-2 {{attributeName}} is recommended",
              {
                attributeName: title,
              }
            )}
          </Typography>
        </Box>
      )}
    </Container>
  );
}
