import { useEffect } from "react";
import { useNavigateWithState } from "../../utils/useNavigateWithState";
import { getAppStateFromUrl } from "../RadarExport/utils";
import qs from "qs";
import { STEP_URLS } from "../../constants";
import { setAppState } from "../../utils";
import { GlobalLoader } from "../../components/Loader";

function ConfigLoader() {
  const navigate = useNavigateWithState();

  useEffect(() => {
    const state = getAppStateFromUrl();
    const { sheetId, localeId } = state;

    if (sheetId || localeId) {
      const urlParams = qs.stringify({ sheetId, localeId });
      navigate(`${STEP_URLS.weighting}?${urlParams}`);
    } else {
      navigate(`${STEP_URLS.weighting}`);
    }

    setTimeout(() => {
      setAppState(state); // Show loaded state after loader
    }, 200);
  }, [navigate]);

  return <GlobalLoader />;
}

export default ConfigLoader;
