import { CustomizationVariables, SelectionMap } from "../@types";
import {
  AppState,
  selectedHorizonVar,
  selectedKpisVar,
  selectedMeasuresVar,
  selectedPresetVar,
  showOnlyImportantConnectionsVar,
  authTokenVar,
  userEmailVar,
} from "../apollo/state";
import qs from "qs";
import { parsePosition } from "../components/Radar/positioning-utils";

const WEB_STORAGE_EMAIL_KEY = "esg-email";
const WEB_STORAGE_TOKEN_KEY = "esg-token";
const WEB_STORAGE_SHEET_ID_KEY = "esg-sheet-id";

export function ceilValueByScale(value: number, scale: number) {
  return Math.ceil(value / scale) * scale;
}

export function getFieldOpacity(weightValue: number) {
  return ceilValueByScale(weightValue, 0.2);
}

export const isTopQuadrant = (quadrant: number) => quadrant === 1 || quadrant === 2;

export const isLeftQuadrant = (quadrant: number, position: string | null) => {
  if (quadrant === 0) {
    return parsePosition(position!).x < 40;
  }

  return quadrant === 1 || quadrant === 3;
};

export function getSelectedElementsCount(map: SelectionMap) {
  return Object.values(map).filter(Boolean).length;
}

export function getAppState(): AppState {
  return {
    ...getCustomizationParams(),
    selectedPreset: selectedPresetVar(),
    selectedKpis: selectedKpisVar(),
    selectedMeasures: selectedMeasuresVar(),
    selectedHorizon: selectedHorizonVar(),
    showOnlyImportantConnections: showOnlyImportantConnectionsVar(),
  };
}

export function setAppState({
  selectedHorizon,
  selectedKpis,
  selectedMeasures,
  selectedPreset,
  showOnlyImportantConnections,
}: AppState) {
  selectedPresetVar(selectedPreset);
  selectedKpisVar(selectedKpis);
  selectedMeasuresVar(selectedMeasures);
  selectedHorizonVar(selectedHorizon);
  showOnlyImportantConnectionsVar(showOnlyImportantConnections);
}

export function getCustomizationParams() {
  const { sheetId = undefined, localeId = undefined } = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  }) as CustomizationVariables;

  const retValue: any = {};

  if (sheetId) {
    retValue.sheetId = sheetId;
  }

  if (localeId) {
    retValue.localeId = localeId;
  }
  return retValue;
}

export function storeUserProfileData(email: string, authToken: string) {
  const { sheetId } = getCustomizationParams();
  userEmailVar(email);
  authTokenVar(authToken);
  sessionStorage.setItem(WEB_STORAGE_EMAIL_KEY, email);
  sessionStorage.setItem(WEB_STORAGE_TOKEN_KEY, authToken);
  sessionStorage.setItem(WEB_STORAGE_SHEET_ID_KEY, sheetId);
}

export function removeUserProfileData() {
  userEmailVar(null);
  authTokenVar(null);
  sessionStorage.removeItem(WEB_STORAGE_EMAIL_KEY);
  sessionStorage.removeItem(WEB_STORAGE_TOKEN_KEY);
  sessionStorage.removeItem(WEB_STORAGE_SHEET_ID_KEY);
}

export function loadUserProfileData() {
  const savedSheetId = sessionStorage.getItem(WEB_STORAGE_SHEET_ID_KEY);
  const { sheetId } = getCustomizationParams();

  if (savedSheetId === sheetId) {
    const email = sessionStorage.getItem(WEB_STORAGE_EMAIL_KEY);
    const authToken = sessionStorage.getItem(WEB_STORAGE_TOKEN_KEY);
    userEmailVar(email);
    authTokenVar(authToken);
  }
}
