import { useEffect, useMemo, useState } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import {
  AvailablePresets,
  DtoField,
  DtoFieldType,
  GetFieldsDataResponse,
  KpiBySubFieldMap,
  MeasureBySubFieldMap,
  ProcessedDtoSubField,
  WeightByFieldMap,
} from "../types";
import { GET_FIELDS_DATA } from "../graphql";
import {
  getAvailablePresets,
  getEntityByIdMap,
  getWeightByFieldMap,
  groupKpisBySubField,
  groupMeasuresBySubField,
  processMatrix,
  processSubFields,
} from "../utils";
import {
  selectedHorizonVar,
  selectedPresetNameVar,
  selectedPresetVar,
} from "../../../apollo/state";
import { HORIZON_MODE } from "../../../constants";
import { sortBy } from "lodash-es";
import { EMPTY_ARR, EMPTY_MATRIX } from "./constants";
import { getCustomizationParams } from "../../../utils";
import { CustomizationVariables } from "../../../@types";

export function useData() {
  const customizationParams = getCustomizationParams();
  const [dataInitialized, setDataInitialized] = useState<boolean>(false);
  const [availablePresets, setAvailablePresets] = useState<AvailablePresets>(
    {} as AvailablePresets
  );
  const [weightByFieldMap, setWeightByFieldMap] = useState<WeightByFieldMap>({});

  const [kpiBySubFieldMap, setKpiBySubFieldMap] = useState<KpiBySubFieldMap>({});
  const [measuresBySubFieldMap, setMeasuresBySubFieldMap] = useState<MeasureBySubFieldMap>({});

  const getFieldsQuery = useQuery<GetFieldsDataResponse, CustomizationVariables>(GET_FIELDS_DATA, {
    variables: customizationParams,
  });
  const fields = getFieldsQuery.data?.fields || EMPTY_ARR;
  const fieldTypes = getFieldsQuery.data?.fieldTypes || EMPTY_ARR;
  const weights = getFieldsQuery.data?.weights || EMPTY_ARR;
  const kpis = getFieldsQuery.data?.kpis || EMPTY_ARR;
  const measures = getFieldsQuery.data?.measures || EMPTY_ARR;
  const subFields = getFieldsQuery.data?.subFields || EMPTY_ARR;
  const shortMatrix = getFieldsQuery.data?.shortMatrix || EMPTY_MATRIX;
  const longMatrix = getFieldsQuery.data?.longMatrix || EMPTY_MATRIX;
  const matrixLabels = getFieldsQuery.data?.matrixLabels || EMPTY_MATRIX;
  const queryLoading = getFieldsQuery.loading;

  const selectedHorizon = useReactiveVar(selectedHorizonVar);

  const sortedFields = useMemo<DtoField[]>(() => {
    return sortBy(fields, "position");
  }, [fields]);

  const fieldTypesMap = useMemo(() => {
    return getEntityByIdMap<DtoFieldType>(fieldTypes);
  }, [fieldTypes]);

  const processedSubFields = useMemo(() => {
    return processSubFields(subFields);
  }, [subFields]);

  useEffect(() => {
    if (sortedFields.length && weights.length && !queryLoading) {
      const availablePresets = getAvailablePresets(weights);
      setAvailablePresets(availablePresets);
      selectedPresetVar(availablePresets.standard.key);
      selectedPresetNameVar(availablePresets.standard.name);

      setWeightByFieldMap(getWeightByFieldMap(weights));
      setKpiBySubFieldMap(groupKpisBySubField(kpis));
      setMeasuresBySubFieldMap(groupMeasuresBySubField(measures));

      setDataInitialized(true);
    }
  }, [sortedFields, weights, kpis, queryLoading, measures]);

  const matrix = useMemo(() => {
    if (dataInitialized && !queryLoading) {
      return processMatrix<number>(
        selectedHorizon === HORIZON_MODE.short ? shortMatrix : longMatrix
      );
    }

    return EMPTY_MATRIX;
  }, [dataInitialized, selectedHorizon, shortMatrix, longMatrix, queryLoading]);

  const connectionLabels = useMemo(() => {
    if (dataInitialized && !queryLoading) {
      return processMatrix<string>(matrixLabels);
    }

    return EMPTY_MATRIX;
  }, [dataInitialized, matrixLabels, queryLoading]);

  const fieldsMap = useMemo(() => {
    return getEntityByIdMap<DtoField>(sortedFields);
  }, [sortedFields]);

  const subFieldsMap = useMemo(() => {
    return getEntityByIdMap<ProcessedDtoSubField>(processedSubFields);
  }, [processedSubFields]);

  return {
    dataInitialized: dataInitialized && !queryLoading,
    fields: sortedFields,
    subFields: processedSubFields,
    subFieldsMap,
    fieldsMap,
    fieldTypesMap,
    fieldTypes,
    weights,
    kpis,
    measures,
    availablePresets,
    weightByFieldMap,
    kpiBySubFieldMap,
    measuresBySubFieldMap,
    matrix,
    connectionLabels,
  };
}
