import { NavigateProps } from "react-router";
import { Navigate } from "react-router-dom";
import { generatePathWithParams } from "../../utils/useNavigateWithState";
import { getCustomizationParams } from "../../utils";

const initialCustomizationParams = getCustomizationParams();

export function NavigateWithState(props: NavigateProps) {
  return (
    <Navigate
      {...props}
      to={generatePathWithParams(props.to.toString(), initialCustomizationParams)}
    />
  );
}
