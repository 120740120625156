import { forwardRef, InputHTMLAttributes } from "react";
import styled from "styled-components";
import { Ref } from "../../@types";
import Box, { BoxProps } from "../Box";

const StyledMark = styled.span`
  display: inline-block;
  position: relative;
  border: 1px solid var(--form-input-color);
  width: 16px;
  height: 16px;
  left: 0;
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: -3px;

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: var(--form-input-color);
    opacity: 0;
    left: 50%;
    top: 50%;
    position: absolute;
    transition: all 110ms;
  }
`;

const StyledInput = styled(Box)`
  position: absolute;
  visibility: hidden;
  display: none;

  &:checked + ${StyledMark} {
    &::after {
      width: 10px;
      height: 10px;
      opacity: 1;
      left: 2px;
      top: 2px;
    }
  }
`;

type RadioProps = { name: string } & InputHTMLAttributes<HTMLInputElement> & BoxProps;

const Radio = forwardRef<Ref, RadioProps>(({ name, ...rest }, ref) => (
  <>
    <StyledInput forwardedAs="input" name={name} type="radio" ref={ref} {...rest} />
    <StyledMark />
  </>
));

export default Radio;
