import styled from "styled-components";

export const LoginWrapper = styled.div`
  position: absolute;
  bottom: 100px;
  color: var(--base-text-color);
`;

export const LoginInputWrapper = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
`;

export const LoginLabel = styled.label`
  margin-top: 10px;
  margin-bottom: 2px;
  padding-left: 5px;
  font-size: 14px;
  font-weight: 600;
`;

export const LoginInput = styled.input`
  padding: 5px 10px;
  border: 1px solid var(--form-input-color);
  width: 300px;
`;

export const AuthFailed = styled.div`
  margin: 5px;
  font-size: 14px;
  font-weight: 600;
  color: var(--radar-bottom-color);
`;
