import { useMemo } from "react";
import { HydratedField } from "../types";

const IMPORTANT_CONNECTION_THRESHOLD = 0.8;

export function useFieldErrors(fields: HydratedField[]) {
  return useMemo(() => {
    let importantConnectionNotSelected: string[] = [];

    const importantFieldNotSelected = fields
      .filter(
        cn =>
          cn.weight > IMPORTANT_CONNECTION_THRESHOLD &&
          !(cn.hasKpiSelected && cn.hasMeasureSelected) &&
          !cn.locked
      )
      .map(cn => cn.id);

    fields.forEach(field => {
      importantConnectionNotSelected = [
        ...importantConnectionNotSelected,
        ...field.importantConnectionNotSelected,
      ];
    });

    return {
      importantFieldNotSelected,
      importantConnectionNotSelected,
    };
  }, [fields]);
}
