import Box, { BoxProps } from "../Box";

export function InfoIcon(props: BoxProps) {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      fill="var(--icon_radar-tooltip-exclamation)"
      viewBox="0 0 24 24"
      width="20px"
      height="20px"
      {...props}
    >
      <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z" />
    </Box>
  );
}

export function WarningIcon(props: BoxProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} {...props}>
      <g data-name="Group 1277">
        <path
          data-name="Path 1470"
          d="M13.113 1.261a12 12 0 1 1-12 12 12 12 0 0 1 12-12Z"
          fill="var(--icon_sidebar-warning-background)"
        />
        <path
          data-name="Path 706"
          d="M13 0A13.009 13.009 0 0 0 0 13a13.009 13.009 0 0 0 13 13 13 13 0 0 0 13-13A13 13 0 0 0 13 0Zm0 3A10 10 0 0 0 3 13a10 10 0 0 0 10 10 10 10 0 0 0 10-10A10 10 0 0 0 13 3Z"
          fill="var(--icon_sidebar-warning-color)"
          fillRule="evenodd"
        />
        <path
          data-name="Path 1469"
          d="M11.408 19.558a.688.688 0 0 0 .114.38l.368.552a.768.768 0 0 0 .572.307h1.325a.768.768 0 0 0 .572-.307l.367-.551a.81.81 0 0 0 .115-.38v-.842h-3.435v.842Zm1.717-9.76a3.772 3.772 0 0 0-3.781 3.762 3.761 3.761 0 0 0 .936 2.488 6.18 6.18 0 0 1 1.12 1.965v.017h3.446v-.017a6.178 6.178 0 0 1 1.125-1.965 3.684 3.684 0 0 0 .936-2.488 3.777 3.777 0 0 0-3.782-3.762Zm2.075 5.588a7.527 7.527 0 0 0-1.054 1.63H12.11a7.527 7.527 0 0 0-1.054-1.63 2.785 2.785 0 0 1-.681-1.826 2.724 2.724 0 0 1 2.731-2.731 2.753 2.753 0 0 1 2.769 2.731 2.789 2.789 0 0 1-.675 1.826Zm-2.419-3.869a1.721 1.721 0 0 0-1.718 1.719.344.344 0 0 0 .344.344.344.344 0 0 0 .344-.344 1.032 1.032 0 0 1 1.03-1.032.343.343 0 0 0 .344-.344.344.344 0 0 0-.344-.344Z"
          fill="var(--icon_sidebar-warning-color)"
        />
        <path
          data-name="Path 1464"
          d="M13 6.023v2.611"
          fill="none"
          stroke="var(--icon_sidebar-warning-color)"
          strokeLinecap="round"
        />
        <path
          data-name="Path 1465"
          d="m18.75 7.955-1.846 1.847"
          fill="none"
          stroke="var(--icon_sidebar-warning-color)"
          strokeLinecap="round"
        />
        <path
          data-name="Path 1466"
          d="M9.34 9.795 7.493 7.95"
          fill="none"
          stroke="var(--icon_sidebar-warning-color)"
          strokeLinecap="round"
        />
        <path
          data-name="Path 1467"
          d="M20.78 13.324h-2.611"
          fill="none"
          stroke="var(--icon_sidebar-warning-color)"
          strokeLinecap="round"
        />
        <path
          data-name="Path 1468"
          d="M8.073 13.325H5.462"
          fill="none"
          stroke="var(--icon_sidebar-warning-color)"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}

export function LockIcon(props: BoxProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" {...props}>
      <defs>
        <clipPath id="clip-path">
          <rect width="26" height="26" fill="none" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip-path)">
        <path
          d="M17.394,11.164v-2.1A3.669,3.669,0,0,0,13.773,5.36H12.185A3.669,3.669,0,0,0,8.564,9.068v2.1a1.46,1.46,0,0,0-1.208,1.422v6.6A1.467,1.467,0,0,0,8.834,20.64h8.331a1.468,1.468,0,0,0,1.479-1.446v-6.6a1.461,1.461,0,0,0-1.25-1.429M13.687,16.1v1.649a.5.5,0,0,1-.5.489h-.125a.5.5,0,0,1-.5-.489V16.123a1.458,1.458,0,0,1-.958-1.361,1.49,1.49,0,0,1,2.979,0,1.456,1.456,0,0,1-.9,1.336m2.5-4.952H9.774V9.068a2.452,2.452,0,0,1,2.411-2.486h1.588a2.452,2.452,0,0,1,2.411,2.486Z"
          fill="#404040"
        />
        <path
          d="M13,0A13,13,0,1,0,26,13,13.009,13.009,0,0,0,13,0m0,3A10,10,0,1,0,23,13,10,10,0,0,0,13,3"
          fill="#404040"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
