import {
  Weight,
  WeightByFieldMap,
  DtoKpi,
  DtoMatrix,
  HydratedField,
  FieldsConnectionMap,
  HydratedConnectedField,
  AvailablePresets,
  DtoMeasure,
  DtoSubField,
  ProcessedDtoSubField,
  KpiBySubFieldMap,
  MeasureBySubFieldMap,
  HydratedSubField,
  HydratedKpi,
  HydratedMeasure,
  DtoPresetConfig,
  EntityGetter,
  AttributeWithFields,
} from "./types";
import { keyBy, groupBy, orderBy, take, sum, uniq, sortBy } from "lodash-es";
import { GenericObject, SelectionMap } from "../../@types";
import { matchPath, useLocation } from "react-router-dom";
import { STEP_URLS } from "../../constants";
import {
  selectedKpisVar,
  showOnlyImportantConnectionsVar,
  selectedPresetVar,
  selectedPresetNameVar,
  selectedMeasuresVar,
} from "../../apollo/state";
import { FIELD_ID_PARAM, SUB_FIELD_ID_PARAM } from "../../constants/routing";
import { useNavigateWithState } from "../../utils/useNavigateWithState";

export const TOP_FIELDS_COUNT = 5;

export function getAvailablePresets(weights: Weight[]) {
  const [standard, ...custom] = weights[0].presets;
  return {
    standard,
    custom,
  };
}

export function getWeightByFieldMap(weights: Weight[]) {
  return weights.reduce<WeightByFieldMap>((acc, weight) => {
    acc[weight.field] = keyBy(weight.presets, "key");
    return acc;
  }, {});
}

export function getEntityByIdMap<T>(fields: T[]) {
  return keyBy(fields, "id");
}

function groupSubFieldsRelatedEntity<T extends { id: string; subFields: string[] }>(items: T[]) {
  return items.reduce<{ [key: string]: T[] }>((acc, item) => {
    item.subFields.forEach(subFieldId => {
      if (!acc[subFieldId]) {
        acc[subFieldId] = [];
      }

      if (!acc[subFieldId].find(sf => sf.id === item.id)) {
        acc[subFieldId].push(item);
      }
    });

    return acc;
  }, {});
}

export function groupKpisBySubField(kpis: DtoKpi[]) {
  return groupSubFieldsRelatedEntity<DtoKpi>(kpis);
}

export function groupMeasuresBySubField(measures: DtoMeasure[]) {
  return groupSubFieldsRelatedEntity<DtoMeasure>(measures);
}

export function getFieldIdFromSubFieldId(subFieldId: string) {
  const [fieldId] = subFieldId?.split(".");
  return fieldId;
}

export function processSubFields(subFields: DtoSubField[]) {
  return subFields.map<ProcessedDtoSubField>(sf => {
    return { ...sf, fieldId: getFieldIdFromSubFieldId(sf.id) };
  });
}

export function groupSubFieldsByFieldId(subFields: HydratedSubField[]) {
  return groupBy(subFields, "fieldId");
}

export function processMatrix<TValueType>(matrix: DtoMatrix<TValueType>) {
  function getIndex(i: number) {
    return (i + 1).toString();
  }

  return matrix.values.reduce<GenericObject>((acc, item, i) => {
    acc[getIndex(i)] = item.reduce<GenericObject>((subAcc, subItem, j) => {
      subAcc[getIndex(j)] = subItem;
      return subAcc;
    }, {});
    return acc;
  }, {});
}

export function someUrlsMatches(urls: string[], pathName: string, isNot: boolean) {
  if (isNot) {
    return urls.every(pattern => {
      return !matchPath(pattern, pathName);
    });
  } else {
    return urls.some(pattern => {
      return !!matchPath(pattern, pathName);
    });
  }
}

export function useUrlMatching(urls: string[], isNot: boolean = false) {
  const location = useLocation();
  return someUrlsMatches(urls, location.pathname, isNot);
}

export function useTagClickEnabled() {
  return useUrlMatching([
    STEP_URLS.pickFields,
    STEP_URLS.pickSubFields,
    STEP_URLS.pickKeysForSubField,
  ]);
}

export function useRadarTooltipEnabled() {
  return useUrlMatching([STEP_URLS.start, STEP_URLS.intro], true);
}

export function usePreviewEnabled() {
  return useUrlMatching([STEP_URLS.preview]);
}

export function useNavigateField(subField?: boolean) {
  const navigate = useNavigateWithState();

  return (fieldId: string) => {
    if (subField) {
      navigate(STEP_URLS.pickKeysForSubField.replace(SUB_FIELD_ID_PARAM, fieldId));
    } else {
      navigate(STEP_URLS.pickSubFields.replace(FIELD_ID_PARAM, fieldId));
    }
  };
}

export function useCurrentField(hydratedFieldsMap: Record<string, HydratedField>) {
  const location = useLocation();
  const matchField = matchPath(STEP_URLS.pickSubFields, location.pathname);
  const matchSubField = matchPath(STEP_URLS.pickKeysForSubField, location.pathname);

  if (matchField) {
    return hydratedFieldsMap[matchField.params.fieldId!];
  }

  if (matchSubField) {
    const [fieldId] = matchSubField.params.subFieldId!.split(".");
    return hydratedFieldsMap[fieldId.trim()];
  }
}

export function useCurrentSubField(hydratedFieldsMap: Record<string, HydratedField>) {
  const location = useLocation();
  const matchSubField = matchPath(STEP_URLS.pickKeysForSubField, location.pathname);

  if (matchSubField) {
    const { subFieldId } = matchSubField.params;
    const [fieldId] = subFieldId!.split(".");
    const field = hydratedFieldsMap[fieldId.trim()];
    return field?.subFields?.find(sf => sf.id === subFieldId!);
  }
}

export function getStaticTopConnections(matrix: GenericObject, connectionLabels: GenericObject) {
  const keys = Object.keys(matrix);
  return keys.reduce<FieldsConnectionMap>((acc, key) => {
    const values = orderBy(
      Object.keys(matrix[key])
        .filter(innerKey => innerKey !== key)
        .map(innerKey => ({
          field: innerKey,
          value: matrix[key][innerKey],
          label: connectionLabels[key][innerKey],
        })),
      ["value"],
      ["desc"]
    );
    acc[key] = take(values, TOP_FIELDS_COUNT).map((item, index) => ({
      ...item,
      size: TOP_FIELDS_COUNT - index,
    }));
    return acc;
  }, {});
}

export function getManyToManyConnections(
  matrix: GenericObject,
  connectionLabels: GenericObject,
  selectedFields: SelectionMap,
  staticFieldsConnectionsMap: FieldsConnectionMap
) {
  const connectedFields: FieldsConnectionMap = {};
  const selectedFieldIds = Object.keys(selectedFields).filter(key => selectedFields[key]);
  const fieldsWithSum = selectedFieldIds.reduce<GenericObject>((acc, key) => {
    acc[key] = {
      sum: sum(Object.values(matrix[key])),
    };
    return acc;
  }, {});

  const totalSum = sum(selectedFieldIds.map(id => fieldsWithSum[id].sum));

  const fieldsCalculations = selectedFieldIds.reduce<GenericObject>((acc, key) => {
    const field = acc[key];
    const weightFactor = field.sum / totalSum;
    const adjustedMatrix = Object.keys(matrix[key]).reduce<GenericObject>((subAcc, subKey) => {
      subAcc[subKey] = matrix[key][subKey] * weightFactor;
      return subAcc;
    }, {});
    acc[key] = { ...field, weightFactor, adjustedMatrix };
    return acc;
  }, fieldsWithSum);

  const adjustedMatrixArray = selectedFieldIds.reduce<any[]>((acc, key) => {
    const fieldMatrix = fieldsCalculations[key].adjustedMatrix;
    const currFieldConnections = Object.keys(fieldMatrix).map(subKey => {
      return {
        sourceFieldId: key,
        targetFieldId: subKey,
        value: fieldMatrix[subKey],
        label: connectionLabels[key][subKey],
      };
    });

    return [...acc, ...currFieldConnections];
  }, []);

  const topConnections = take(
    orderBy(adjustedMatrixArray, ["value"], ["desc"]),
    TOP_FIELDS_COUNT * selectedFieldIds.length
  );

  topConnections.forEach((tc, index) => {
    if (!connectedFields[tc.sourceFieldId]) {
      connectedFields[tc.sourceFieldId] = [];
    }
    connectedFields[tc.sourceFieldId].push({
      field: tc.targetFieldId,
      value: tc.value,
      label: tc.label,
      size: TOP_FIELDS_COUNT - Math.floor(index / selectedFieldIds.length),
    });
  });

  return { ...staticFieldsConnectionsMap, ...connectedFields };
}

export function getFieldErrors(
  isSelected: boolean,
  selectedFields: Record<string, boolean>,
  connectedFields: HydratedConnectedField[]
) {
  let importantConnectionNotSelected: string[] = [];

  if (isSelected) {
    importantConnectionNotSelected = connectedFields
      .filter(cn => cn.size === 5 && !selectedFields[cn.id])
      .map(cn => cn.id);
  }

  return { importantConnectionNotSelected };
}

export function resetSelectedData(availablePresets: AvailablePresets) {
  selectedPresetVar(availablePresets.standard.key);
  selectedPresetNameVar(availablePresets.standard.name);
  selectedKpisVar({});
  selectedMeasuresVar({});
  showOnlyImportantConnectionsVar(false);
}

export function hasAllFieldTypeSelected(fields: HydratedField[]) {
  const uniqFieldTypes = uniq(fields.map(f => f.type.id));
  const selectedFields = fields.filter(f => f.hasKpiSelected && f.hasMeasureSelected);
  return uniqFieldTypes.every(fType => selectedFields.find(sf => sf.type.id === fType));
}

export function processSubFieldsRelatedData(
  acc: GenericObject,
  subField: HydratedSubField,
  relatedData: KpiBySubFieldMap | MeasureBySubFieldMap,
  selectionMap: SelectionMap,
  selectedPreset: keyof DtoPresetConfig
) {
  return (relatedData[subField.id] || []).reduce((relatedDataAcc, relatedItem) => {
    relatedDataAcc[relatedItem.id] = {
      ...relatedItem,
      isSelected: selectionMap[relatedItem.id],
      relevance: (relatedItem[selectedPreset] * 100).toFixed(0),
    };
    return relatedDataAcc;
  }, acc);
}

export function getSubFieldRelatedData(
  subFields: HydratedSubField[],
  kpiBySubFieldMap: KpiBySubFieldMap,
  measuresBySubFieldMap: MeasureBySubFieldMap,
  selectedKpis: SelectionMap,
  selectedMeasures: SelectionMap,
  selectedPreset: keyof DtoPresetConfig
) {
  const { fieldKpisMap, fieldMeasuresMap } = subFields.reduce<{
    fieldKpisMap: { [key: string]: HydratedKpi };
    fieldMeasuresMap: { [key: string]: HydratedMeasure };
  }>(
    (acc, sf) => {
      const fieldKpisMap = processSubFieldsRelatedData(
        acc.fieldKpisMap,
        sf,
        kpiBySubFieldMap,
        selectedKpis,
        selectedPreset
      );

      const fieldMeasuresMap = processSubFieldsRelatedData(
        acc.fieldMeasuresMap,
        sf,
        measuresBySubFieldMap,
        selectedMeasures,
        selectedPreset
      );

      return { fieldKpisMap, fieldMeasuresMap };
    },
    { fieldKpisMap: {}, fieldMeasuresMap: {} }
  );

  const fieldKpis = orderBy(Object.values(fieldKpisMap), ["relevance"], ["desc"]);

  const fieldMeasures = orderBy(Object.values(fieldMeasuresMap), ["relevance"], ["desc"]);

  return { fieldKpis, fieldMeasures };
}

export const getAttributesMapFromFields = (fields: HydratedField[], entityGetter: EntityGetter) => {
  return fields.reduce<Record<string, AttributeWithFields>>((acc, field) => {
    field[entityGetter]
      .filter(attr => attr.isSelected)
      .forEach(attr => {
        if (!acc[attr.id]) {
          acc[attr.id] = { ...attr, fields: [] };
        }

        if (!acc[attr.id].fields.find(f => f.id === field.id)) {
          acc[attr.id].fields.push(field);
        }
      });

    return acc;
  }, {});
};

export const getAttributesFromFieldsWithSortedFields = (
  fields: HydratedField[],
  entityGetter: EntityGetter
) => {
  const attrMap = getAttributesMapFromFields(fields, entityGetter);
  return Object.values(attrMap).map(attr => {
    return { ...attr, fields: sortBy(attr.fields, "quadrant") };
  });
};
