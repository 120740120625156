import {
  AccordionContainer,
  AttributesWithFieldsContainer,
  AttributesWithFieldsListContainer,
  SubTitle,
  TagContainer,
} from "./styled";
import { useTranslation } from "react-i18next";
import { AttributeWithFields } from "../types";
import Tag from "../../../components/Tag";
import { Accordion, AccordionDetail, AccordionSummary } from "../../../components/Accordion";
import Typography from "../../../components/Typography";
import Box from "../../../components/Box";

type SubTitleProps = {
  count: number;
  fieldsCount: number;
};

type AttributesWithFieldsListProps = {
  attributesWithFields: AttributeWithFields[];
};

export const MeasuresSubTitle = ({ count, fieldsCount }: SubTitleProps) => {
  const { t } = useTranslation();

  return (
    <SubTitle>
      {t(
        "wizard.preview.measureSubTitle",
        `{{measureCount}} selected {{measureLabel}} in {{fieldsCount}} {{fieldLabel2}}`,
        {
          measureCount: count,
          measureLabel:
            count > 1
              ? t("wizard.guide.measures", "Measures")
              : t("wizard.guide.measure", "Measure"),
          fieldsCount,
          fieldLabel2:
            fieldsCount > 1
              ? t("wizard.guide.fields2", "fields")
              : t("wizard.guide.field", "field"),
        }
      )}
    </SubTitle>
  );
};

export const KpisSubTitle = ({ count, fieldsCount }: SubTitleProps) => {
  const { t } = useTranslation();

  return (
    <SubTitle>
      {t(
        "wizard.preview.kpiSubTitle",
        `{{kpiCount}} selected {{kpiLabel}} in {{fieldsCount}} {{fieldLabel2}}`,
        {
          kpiCount: count,
          kpiLabel:
            count > 1 ? t("wizard.guide.kpis", "Measures") : t("wizard.guide.kpi", "Measure"),
          fieldsCount,
          fieldLabel2:
            fieldsCount > 1
              ? t("wizard.guide.fields2", "fields")
              : t("wizard.guide.field", "field"),
        }
      )}
    </SubTitle>
  );
};

export const AttributesWithFieldsList = ({
  attributesWithFields,
}: AttributesWithFieldsListProps) => {
  return (
    <AttributesWithFieldsListContainer>
      {attributesWithFields.map(awf => (
        <AttributesWithFieldsContainer>
          <AccordionContainer>
            <Accordion key={`awf${awf.id}`} m="0 10px 20px 0">
              <AccordionSummary>{awf.shortName}</AccordionSummary>
              <AccordionDetail>
                <Typography bold m="10px 0 10px 0">
                  {awf.name}
                </Typography>
                <Box
                  sx={{
                    wordWrap: "pre-line",
                    whiteSpace: "break-spaces",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: awf.description,
                  }}
                />
              </AccordionDetail>
            </Accordion>
          </AccordionContainer>
          <TagContainer>
            {awf.fields.map(field => (
              <Box m="0 10px 10px 0" key={field.id}>
                <Tag {...field} />
              </Box>
            ))}
          </TagContainer>
        </AttributesWithFieldsContainer>
      ))}
    </AttributesWithFieldsListContainer>
  );
};
