import { SvgContainer } from "../../styled";
import { useEffect, useState } from "react";
import { useSettings } from "../../../../context/Settings";
import { RadarImage } from "./styled";

const DEFAULT_BG_URL = "/images/radar/bg.svg";

function Background() {
  const [svgData, setSvgData] = useState<string>("");
  const { radarBackground, radarBackgroundRear } = useSettings();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(DEFAULT_BG_URL);
        setSvgData(await res.text());
      } catch {}
    };

    if (!radarBackground) {
      fetchData();
    }
  }, [radarBackground]);

  return svgData ? (
    <SvgContainer viewBox="0 0 1150 1080" dangerouslySetInnerHTML={{ __html: svgData }} />
  ) : (
    <RadarImage
      src={radarBackground}
      alt="Radar Background"
      className="custom-radar-bg-image"
      style={
        radarBackgroundRear
          ? {
              backgroundImage: `url("${radarBackgroundRear}")`,
            }
          : undefined
      }
    />
  );
}
export default Background;
