import React from "react";
import { useReactiveVar } from "@apollo/client";
import { authTokenVar } from "../../apollo/state";
import { Outlet } from "react-router-dom";
import { useSettings } from "../../context/Settings";
import { STEP_URLS } from "../../constants";
import { useUrlMatching } from "../../routes/RadarWizard/utils";
import { NavigateWithState } from "../NavigateWithState";
import { getInvitationToken } from "../../routes/RadarWizard/UserAuth/utils";

export function AuthRouter() {
  const token = useReactiveVar(authTokenVar);
  const { userAccountsSheetId } = useSettings();
  const passwordProtection = !!userAccountsSheetId?.trim();
  const isLoginPage = useUrlMatching([STEP_URLS.login]);
  const isSignupPage = useUrlMatching([STEP_URLS.signup]);
  const invitationToken = getInvitationToken();

  if ((!passwordProtection || token) && isLoginPage) {
    return <NavigateWithState to={STEP_URLS.start} />;
  }

  if (passwordProtection) {
    if (!invitationToken && isSignupPage) {
      return <NavigateWithState to={STEP_URLS.login} />;
    }

    if (!token && !isLoginPage && !isSignupPage) {
      return <NavigateWithState to={STEP_URLS.login} />;
    }
  }

  return <Outlet />;
}
