import styled from "styled-components";

export const SubTitle = styled.div`
  font-size: 18px;
  text-align: center;
  margin-bottom: 30px;
`;

export const AttributesWithFieldsListContainer = styled.div``;

export const AttributesWithFieldsContainer = styled.div`
  display: flex;
  margin: 20px 0;
`;

export const AccordionContainer = styled.div`
  width: calc(100% - 150px);
  padding-right: 20px;
  min-width: 225px;
  word-break: break-word;
  font-size: 13px;
`;

export const TagContainer = styled.div`
  width: 150px;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
`;
