export const FIELD_ID_PARAM = ":fieldId";
export const SUB_FIELD_ID_PARAM = ":subFieldId";
export const INTRO_STEP = ":introId";

export const STEP_URLS = {
  login: "/login",
  signup: "/signup",
  changePassword: "/change-password",
  start: "/start",
  intro: `/intro/${INTRO_STEP}`,
  weighting: "/gewichtung",
  pickFields: "/handlungsfelder",
  pickSubFields: `/handlungsfeld/${FIELD_ID_PARAM}`,
  pickKeysForSubField: `/teilhandlungsfeld/${SUB_FIELD_ID_PARAM}`,
  preview: "/vorschau",
  summary: "/zusammenfassung",
  configLoader: "/konfigurationslader",
};

const REST_API_URL = process.env.REACT_APP_REST_API_URL;

export const EXPORT_API_URLS = {
  image: `${REST_API_URL}/exports/image`,
  pdf: `${REST_API_URL}/exports/pdf`,
  scorecard: `${REST_API_URL}/exports/scorecard`,
  measureslist: `${REST_API_URL}/exports/measures`,
};

export const EXPORT_URLS = {
  image: `/export/image`,
  pdf: `/export/pdf`,
};
