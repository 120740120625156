import { WarningIconProps } from "./types";
import { TAG_ICON_PROPS } from "./constants";

export function getSelectionIcon(hasKpiSelected: boolean, hasMeasureSelected: boolean) {
  if (hasKpiSelected && hasMeasureSelected) {
    return <SelectedIcon />;
  }

  if (hasKpiSelected) {
    return <KpiIcon />;
  }

  if (hasMeasureSelected) {
    return <MeasureIcon />;
  }
}

export function WarningIcon({ title }: WarningIconProps) {
  return (
    <svg {...TAG_ICON_PROPS} xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g data-name="Group 1275" transform="translate(-1060.887 -500.739)">
        <circle
          data-name="Ellipse 34"
          cx={12}
          cy={12}
          r={12}
          transform="translate(1062 502)"
          fill="var(--icon_tag-warning-background)"
        />
        <path
          data-name="Path 706"
          d="M1073.887 500.739a13.009 13.009 0 0 0-13 13 13.009 13.009 0 0 0 13 13 13 13 0 0 0 13-13 13 13 0 0 0-13-13Zm0 3a10 10 0 0 0-10 10 10 10 0 0 0 10 10 10 10 0 0 0 10-10 10 10 0 0 0-10-10Z"
          fill="var(--icon_tag-warning-color)"
          fillRule="evenodd"
        />
        <path
          data-name="Path 1469"
          d="M1072.295 520.297a.688.688 0 0 0 .114.38l.368.552a.768.768 0 0 0 .572.307h1.325a.768.768 0 0 0 .572-.307l.367-.551a.81.81 0 0 0 .115-.38v-.842h-3.435v.842Zm1.717-9.76a3.772 3.772 0 0 0-3.781 3.762 3.761 3.761 0 0 0 .936 2.488 6.18 6.18 0 0 1 1.12 1.965v.017h3.446v-.017a6.178 6.178 0 0 1 1.125-1.965 3.684 3.684 0 0 0 .936-2.488 3.777 3.777 0 0 0-3.782-3.762Zm2.075 5.588a7.527 7.527 0 0 0-1.054 1.63h-2.036a7.527 7.527 0 0 0-1.054-1.63 2.785 2.785 0 0 1-.681-1.826 2.724 2.724 0 0 1 2.731-2.731 2.753 2.753 0 0 1 2.769 2.731 2.789 2.789 0 0 1-.675 1.826Zm-2.419-3.869a1.721 1.721 0 0 0-1.718 1.719.344.344 0 0 0 .344.344.344.344 0 0 0 .344-.344 1.032 1.032 0 0 1 1.03-1.032.343.343 0 0 0 .344-.344.344.344 0 0 0-.344-.344Z"
          fill="var(--icon_tag-warning-color)"
        />
        <path
          data-name="Path 1464"
          d="M1073.887 506.762v2.611"
          fill="none"
          stroke="var(--icon_tag-warning-color)"
          strokeLinecap="round"
        />
        <path
          data-name="Path 1465"
          d="m1079.637 508.694-1.846 1.847"
          fill="none"
          stroke="var(--icon_tag-warning-color)"
          strokeLinecap="round"
        />
        <path
          data-name="Path 1466"
          d="m1070.227 510.534-1.847-1.846"
          fill="none"
          stroke="var(--icon_tag-warning-color)"
          strokeLinecap="round"
        />
        <path
          data-name="Path 1467"
          d="M1081.667 514.063h-2.611"
          fill="none"
          stroke="var(--icon_tag-warning-color)"
          strokeLinecap="round"
        />
        <path
          data-name="Path 1468"
          d="M1068.96 514.064h-2.611"
          fill="none"
          stroke="var(--icon_tag-warning-color)"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}

export function SelectedIcon() {
  return (
    <svg {...TAG_ICON_PROPS}>
      <g transform="translate(-1060.806 -500.739)">
        <circle
          cx="12"
          cy="12"
          r="12"
          transform="translate(1062 502)"
          fill="var(--icon_tag-selected-background)"
        />
        <path
          d="M1757.22-348.505a13.009,13.009,0,0,0-13,13,13.009,13.009,0,0,0,13,13,13,13,0,0,0,13-13A13,13,0,0,0,1757.22-348.505Zm0,3a10,10,0,0,0-10,10,10,10,0,0,0,10,10,10,10,0,0,0,10-10A10,10,0,0,0,1757.22-345.505Z"
          transform="translate(-683.414 849.244)"
          fill="var(--icon_tag-selected-color)"
          fillRule="evenodd"
        />
        <path
          d="M1755.22-330.3l-5-5,1.41-1.41,3.59,3.58,7.59-7.59,1.41,1.42Z"
          transform="translate(-683.414 849.244)"
          fill="var(--icon_tag-selected-color)"
        />
      </g>
    </svg>
  );
}

export function LockIcon() {
  return (
    <svg {...TAG_ICON_PROPS}>
      <defs>
        <clipPath id="clip-path">
          <rect width="26" height="26" fill="none" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip-path)">
        <path d="M13.113,1.261a12,12,0,1,1-12,12,12,12,0,0,1,12-12" fill="#fff" />
        <path
          d="M17.394,11.164v-2.1A3.669,3.669,0,0,0,13.773,5.36H12.185A3.669,3.669,0,0,0,8.564,9.068v2.1a1.46,1.46,0,0,0-1.208,1.422v6.6A1.467,1.467,0,0,0,8.834,20.64h8.331a1.468,1.468,0,0,0,1.479-1.446v-6.6a1.461,1.461,0,0,0-1.25-1.429M13.687,16.1v1.649a.5.5,0,0,1-.5.489h-.125a.5.5,0,0,1-.5-.489V16.123a1.458,1.458,0,0,1-.958-1.361,1.49,1.49,0,0,1,2.979,0,1.456,1.456,0,0,1-.9,1.336m2.5-4.952H9.774V9.068a2.452,2.452,0,0,1,2.411-2.486h1.588a2.452,2.452,0,0,1,2.411,2.486Z"
          fill="#404040"
        />
        <path
          d="M13,0A13,13,0,1,0,26,13,13.009,13.009,0,0,0,13,0m0,3A10,10,0,1,0,23,13,10,10,0,0,0,13,3"
          fill="#404040"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}

export function MeasureIcon() {
  return (
    <svg {...TAG_ICON_PROPS}>
      <g transform="translate(-1060.806 -500.739)">
        <circle
          cx="12"
          cy="12"
          r="12"
          transform="translate(1062 502)"
          fill="var(--icon_tag-selected-background)"
        />
        <path
          d="M1757.22-348.505a13.009,13.009,0,0,0-13,13,13.009,13.009,0,0,0,13,13,13,13,0,0,0,13-13A13,13,0,0,0,1757.22-348.505Zm0,3a10,10,0,0,0-10,10,10,10,0,0,0,10,10,10,10,0,0,0,10-10A10,10,0,0,0,1757.22-345.505Z"
          transform="translate(-683.414 849.244)"
          fill="var(--icon_tag-selected-color)"
          fillRule="evenodd"
        />
      </g>
      <path
        id="wrench"
        d="M7.271,28.627a.663.663,0,0,0,.478-.2L12.281,23.9a.85.85,0,0,1,.917-.188,3.038,3.038,0,0,0,4.165-2.821,3.052,3.052,0,0,0-.03-.427l-1.462,1.462a1.822,1.822,0,0,1-2.576,0l-.1-.1a1.824,1.824,0,0,1,0-2.577l1.386-1.386q-.128-.011-.257-.011a3.039,3.039,0,0,0-2.818,4.173.85.85,0,0,1-.187.92L6.793,27.473a.677.677,0,0,0,0,.956.664.664,0,0,0,.478.2m0,.85a1.526,1.526,0,0,1-1.08-2.606l4.529-4.529a3.889,3.889,0,0,1,5.458-4.872L13.8,19.85a.973.973,0,0,0,0,1.374l.1.1a.972.972,0,0,0,1.374,0l2.406-2.406A3.889,3.889,0,0,1,12.882,24.5L8.351,29.031A1.517,1.517,0,0,1,7.271,29.478Z"
        transform="translate(1.4, -10.4)"
        fill="#000"
      ></path>
    </svg>
  );
}

export function KpiIcon() {
  return (
    <svg {...TAG_ICON_PROPS}>
      <g transform="translate(-1060.806 -500.739)">
        <circle
          cx="12"
          cy="12"
          r="12"
          transform="translate(1062 502)"
          fill="var(--icon_tag-selected-background)"
        />
        <path
          d="M1757.22-348.505a13.009,13.009,0,0,0-13,13,13.009,13.009,0,0,0,13,13,13,13,0,0,0,13-13A13,13,0,0,0,1757.22-348.505Zm0,3a10,10,0,0,0-10,10,10,10,0,0,0,10,10,10,10,0,0,0,10-10A10,10,0,0,0,1757.22-345.505Z"
          transform="translate(-683.414 849.244)"
          fill="var(--icon_tag-selected-color)"
          fillRule="evenodd"
        />
      </g>
      <g id="Pie_Chart" data-name="Pie Chart" transform="translate(-479, -50)" fill="#000">
        <path
          id="Differenzmenge_6"
          data-name="Differenzmenge 6"
          d="M5.362,11.174a5.811,5.811,0,0,1-4.11-9.922,5.8,5.8,0,0,1,4.11-1.7.45.45,0,0,1,.45.45V5.064L9.55,8.751a.45.45,0,0,1,.009.632,5.8,5.8,0,0,1-4.2,1.791ZM4.912.47A4.908,4.908,0,0,0,.45,5.362a4.911,4.911,0,0,0,8.138,3.7L5.046,5.573a.45.45,0,0,1-.134-.32Z"
          transform="translate(485.5 58.237)"
        ></path>
        <path
          id="Schnittmenge_1"
          data-name="Schnittmenge 1"
          d="M0-.45H5.314a.45.45,0,0,1,.318.132A.45.45,0,0,1,5.764,0,6.016,6.016,0,0,1,4.127,4.132a.45.45,0,0,1-.647.009L-.319.317a.45.45,0,0,1-.1-.49A.45.45,0,0,1,0-.45Zm4.844.9H1.081L3.775,3.161A5.112,5.112,0,0,0,4.844.45Z"
          transform="translate(492.928 63.259)"
        ></path>
        <path
          id="Schnittmenge_2"
          data-name="Schnittmenge 2"
          d="M5.586,5.871H0a.45.45,0,0,1-.45-.45V0A.45.45,0,0,1,0-.45,6.007,6.007,0,0,1,4.21,1.26,6,6,0,0,1,6.036,5.408a.45.45,0,0,1-.45.463ZM.45,4.971H5.1A5.107,5.107,0,0,0,3.583,1.9,5.107,5.107,0,0,0,.45.469Z"
          transform="translate(492.449 56.259)"
        ></path>
      </g>
    </svg>
  );
}
