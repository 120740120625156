import { useMemo } from "react";
import { useReactiveVar } from "@apollo/client";
import {
  DtoField,
  HydratedField,
  WeightByFieldMap,
  KpiBySubFieldMap,
  MatrixObject,
  FieldTypesMap,
  MeasureBySubFieldMap,
  DtoPresetConfig,
  ProcessedDtoSubField,
} from "../../types";
import { selectedKpisVar, selectedMeasuresVar, selectedPresetVar } from "../../../../apollo/state";
import {
  getFieldErrors,
  getEntityByIdMap,
  useUrlMatching,
  getSubFieldRelatedData,
  groupSubFieldsByFieldId,
} from "../../utils";
import { STEP_URLS } from "../../../../constants";
import { EXPORT_URLS } from "../../../../constants/routing";
import {
  useHydratedSubFields,
  useManyToManyFieldsConnectionsMap,
  useStaticFieldsConnectionsMap,
} from "./sub-hooks";
import { SelectionMap } from "../../../../@types";

export function useHydratedFields(
  fields: DtoField[],
  fieldsMap: Record<string, DtoField>,
  fieldTypesMap: FieldTypesMap,
  weightByFieldMap: WeightByFieldMap,
  subFields: ProcessedDtoSubField[],
  kpiBySubFieldMap: KpiBySubFieldMap,
  measuresBySubFieldMap: MeasureBySubFieldMap,
  matrix: MatrixObject<number>,
  connectionLabels: MatrixObject<string>,
  fullyActivatedFieldIdsMap: SelectionMap
) {
  const selectedPreset = useReactiveVar(selectedPresetVar);
  const selectedKpis = useReactiveVar(selectedKpisVar);
  const selectedMeasures = useReactiveVar(selectedMeasuresVar);

  const shouldUseManyToManyConnections = useUrlMatching([
    STEP_URLS.summary,
    STEP_URLS.preview,
    EXPORT_URLS.image,
  ]);

  const staticFieldsConnectionsMap = useStaticFieldsConnectionsMap(matrix, connectionLabels);
  const manyToManyFieldsConnectionsMap = useManyToManyFieldsConnectionsMap(
    shouldUseManyToManyConnections,
    matrix,
    connectionLabels,
    fullyActivatedFieldIdsMap,
    staticFieldsConnectionsMap
  );

  const fieldsConnectionsMap = shouldUseManyToManyConnections
    ? manyToManyFieldsConnectionsMap
    : staticFieldsConnectionsMap;

  const hydratedSubFields = useHydratedSubFields(
    subFields,
    measuresBySubFieldMap,
    kpiBySubFieldMap,
    selectedKpis,
    selectedMeasures
  );

  const subFieldsGroupByFieldId = useMemo(() => {
    return groupSubFieldsByFieldId(hydratedSubFields);
  }, [hydratedSubFields]);

  const hydratedSubFieldsMap = useMemo(() => {
    return getEntityByIdMap<ProcessedDtoSubField>(hydratedSubFields);
  }, [hydratedSubFields]);

  const hydratedFields = useMemo(() => {
    if (!Object.keys(weightByFieldMap).length) {
      return [];
    }

    return fields.map<HydratedField>(field => {
      const subFields = subFieldsGroupByFieldId[field.id];

      const { fieldMeasures, fieldKpis } = getSubFieldRelatedData(
        subFields,
        kpiBySubFieldMap,
        measuresBySubFieldMap,
        selectedKpis,
        selectedMeasures,
        selectedPreset as keyof DtoPresetConfig
      );

      const connectedFields = fieldsConnectionsMap[field.id].map(cn => {
        const connectedSubFields = subFieldsGroupByFieldId[cn.field];
        const dtoField = fieldsMap[cn.field];
        return {
          ...dtoField,
          type: fieldTypesMap[dtoField.typeId],
          weight: weightByFieldMap[cn.field][selectedPreset].value,
          connectionValue: cn.value,
          connectionLabel: cn.label,
          size: cn.size,
          hasMeasureSelected: connectedSubFields.some(sf => sf.hasMeasureSelected),
          hasKpiSelected: connectedSubFields.some(sf => sf.hasKpiSelected),
        };
      });

      const isSelected = fullyActivatedFieldIdsMap[field.id];
      const { importantConnectionNotSelected } = getFieldErrors(
        isSelected,
        fullyActivatedFieldIdsMap,
        connectedFields
      );

      return {
        ...field,
        type: fieldTypesMap[field.typeId],
        weight: weightByFieldMap[field.id][selectedPreset].value,
        hasMeasureSelected: subFields.some(sf => sf.hasMeasureSelected),
        hasKpiSelected: subFields.some(sf => sf.hasKpiSelected),
        subFields,
        KPIs: fieldKpis,
        measures: fieldMeasures,
        matrix,
        connectedFields,
        importantConnectionNotSelected,
      };
    });
  }, [
    fields,
    fieldsMap,
    fieldTypesMap,
    weightByFieldMap,
    selectedPreset,
    fullyActivatedFieldIdsMap,
    kpiBySubFieldMap,
    measuresBySubFieldMap,
    matrix,
    fieldsConnectionsMap,
    selectedKpis,
    selectedMeasures,
    subFieldsGroupByFieldId,
  ]);

  const hydratedFieldsMap = useMemo(() => {
    return getEntityByIdMap<HydratedField>(hydratedFields);
  }, [hydratedFields]);

  return {
    hydratedFields,
    hydratedFieldsMap,
    hydratedSubFieldsMap,
  };
}
