import { useTranslation } from "react-i18next";
import Box from "../../../../components/Box";
import Button from "../../../../components/Button";
import { LoginInputWrapper, LoginLabel, LoginInput, LoginWrapper, AuthFailed } from "./../styled";
import { useMutation } from "@apollo/client";
import { SIGNIN_MUTATION } from "../../graphql";
import { useState, ChangeEvent, KeyboardEvent } from "react";
import { AuthComponentProps } from "../types";
import { storeUserProfileData } from "../../../../utils";

export function Login({ sheetId }: AuthComponentProps) {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthFailed, setIsAuthFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsAuthFailed(false);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setIsAuthFailed(false);
  };

  const [signin] = useMutation(SIGNIN_MUTATION, {
    variables: {
      signinArgs: {
        sheetId,
        email: email,
        password: password,
      },
    },
  });

  async function handleOKClick() {
    setIsLoading(true);
    try {
      const {
        data: {
          signin: { token },
        },
      } = await signin();
      storeUserProfileData(email, token);
      setIsAuthFailed(false);
    } catch (e) {
      setIsAuthFailed(true);
    }
    setIsLoading(false);
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleOKClick();
    }
  };

  return (
    <>
      <LoginWrapper>
        <LoginInputWrapper>
          <LoginLabel>{t("wizard.welcome.login", "Login")}</LoginLabel>
          <LoginInput
            type="text"
            value={email}
            onChange={handleEmailChange}
            onKeyDown={handleKeyDown}
          />
        </LoginInputWrapper>
        <LoginInputWrapper>
          <LoginLabel>{t("wizard.welcome.password", "Password")}</LoginLabel>
          <LoginInput
            type="password"
            value={password}
            onChange={handlePasswordChange}
            onKeyDown={handleKeyDown}
          />
        </LoginInputWrapper>
        {isAuthFailed && <AuthFailed>{t("wizard.welcome.loginFailed", "Login failed")}</AuthFailed>}
      </LoginWrapper>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <div>
          <Button onClick={handleOKClick} disabled={isLoading}>
            {t("wizard.welcome.authOK", "OK")}
          </Button>
        </div>
      </Box>
    </>
  );
}
