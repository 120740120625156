import { ForwardedRef, forwardRef } from "react";
import Radio from "../../../../components/Radio";
import Typography from "../../../../components/Typography";

type Props = {
  name: string;
  defaultChecked: boolean;
  value: string;
  title: string;
  subtitle: string;
};

const RadioLabel = forwardRef(
  (
    { name, defaultChecked, value, title, subtitle }: Props,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <label>
        <Radio ref={ref} defaultChecked={defaultChecked} name={name} value={value} />
        <Typography size="18px" as="span" bold>
          {title}
        </Typography>
        <br />
        <Typography m="0 0 0 21px" size="12px">
          {subtitle}
        </Typography>
      </label>
    );
  }
);

export default RadioLabel;
