import React, { useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { getCustomizationParams } from "../utils";
import { CustomizationVariables } from "../@types";
import { parseBoolean, ParseValueHandler } from "./utils";

type Settings = {
  demoMode?: boolean;
  disableExport?: boolean;
  demoModeText?: string;
  disableExportText?: string;
  lockedFieldText?: string;
  radarBackground?: string;
  radarBackgroundRear?: string;
  userAccountsSheetId?: string;
};

type SettingsResponse = {
  settings: {
    name: keyof Settings;
    value: string;
  }[];
};

type SettingsParsers = { [key in keyof Settings]: ParseValueHandler };

export const Parsers: SettingsParsers = {
  demoMode: parseBoolean,
  disableExport: parseBoolean,
};

export const GET_SETTINGS = gql`
  query getSettings($sheetId: String, $localeId: String) {
    settings(sheetId: $sheetId, localeId: $localeId) {
      name
      value
    }
  }
`;

const settingsContext = React.createContext<Settings>({});
const Provider = settingsContext.Provider;

export function SettingsProvider({ children }: React.PropsWithChildren<{}>) {
  const customizationParams = getCustomizationParams();
  const { data } = useQuery<SettingsResponse, CustomizationVariables>(GET_SETTINGS, {
    variables: customizationParams,
  });

  const settings = useMemo<Settings>(() => {
    if (!data) {
      return {};
    }

    return data.settings.reduce<Settings>((acc, item) => {
      if (Parsers[item.name]) {
        acc[item.name] = Parsers[item.name]!(item.value);
      } else {
        // @ts-ignore
        acc[item.name] = item.value;
      }
      return acc;
    }, {});
  }, [data]);

  return <Provider value={settings}>{children}</Provider>;
}

export const useSettings = () => {
  const context = React.useContext(settingsContext);
  if (context === undefined) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }
  return context;
};
