import { useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "../../../components/Box";
import Button from "../../../components/Button";
import SectionTitle from "../../../components/SectionTitle";
import Typography from "../../../components/Typography";
import { EXPORT_API_URLS, STEP_URLS } from "../../../constants/routing";
import { ButtonsContainer } from "../styled";
import StepWrapper from "../../../components/StepWrapper";
import { resetSelectedData } from "../utils";
import Download from "./Download";
import { downloadResource } from "./utils";
import download from "downloadjs";
import { getAppState } from "../../../utils";
import { SummaryProps } from "./types";
import { useNavigateWithState } from "../../../utils/useNavigateWithState";
import { useSettings } from "../../../context/Settings";
import { LockedInfo } from "../../../components/LockedInfo";

function Summary({ availablePresets }: SummaryProps) {
  const navigate = useNavigateWithState();
  const { t } = useTranslation();
  const { disableExport, disableExportText } = useSettings();
  const [isPdfDownloading, setIsPdfDownloading] = useState(false);
  const [isXlsDownloading, setIsXlsDownloading] = useState(false);
  const [isMeasuresListDownloading, setIsMeasuresListDownloading] = useState(false);

  async function downloadPdf() {
    setIsPdfDownloading(true);
    await downloadResource(EXPORT_API_URLS.pdf, "radar.pdf");
    setIsPdfDownloading(false);
  }

  async function downloadScoreCard() {
    setIsXlsDownloading(true);
    await downloadResource(EXPORT_API_URLS.scorecard, "scorecard.xlsx");
    setIsXlsDownloading(false);
  }

  async function downloadMeasureList() {
    setIsMeasuresListDownloading(true);
    await downloadResource(EXPORT_API_URLS.measureslist, "measureslist.xlsx");
    setIsMeasuresListDownloading(false);
  }

  function saveConfig() {
    const state = getAppState();
    download(JSON.stringify(state, null, 4), "config.esg");
  }

  return (
    <StepWrapper
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <SectionTitle>{t("wizard.summary.title", "ESG SCORECARD")}</SectionTitle>
      <Box sx={{ flex: "1" }}>
        <Download
          icon="pdf"
          title={t("wizard.summary.downloadPDF", "ESG RADAR PDF")}
          onDownloadClick={downloadPdf}
          disabled={isPdfDownloading}
        />
        <Download
          icon="xls"
          title={t("wizard.summary.downloadXLS", "ESG SCORECARD")}
          onDownloadClick={downloadScoreCard}
          disabled={isXlsDownloading}
        />
        <Download
          icon="xls"
          title={t("wizard.summary.downloadMeasuresList", "MEASURES LIST")}
          onDownloadClick={downloadMeasureList}
          disabled={isMeasuresListDownloading}
        />
        {disableExport && disableExportText && <LockedInfo>{disableExportText}</LockedInfo>}
      </Box>
      <ButtonsContainer sx={{ flexWrap: "wrap" }}>
        <Button
          onClick={() => {
            navigate(STEP_URLS.preview);
          }}
        >
          {t("wizard.summary.edit", `Edit`)}
        </Button>
        <Button
          onClick={() => {
            resetSelectedData(availablePresets);
            navigate(STEP_URLS.weighting);
          }}
        >
          {t("wizard.summary.startOver", `Start Over`)}
        </Button>
        <Button sx={{ marginLeft: "auto" }} onClick={saveConfig}>
          {t("wizard.summary.save", `Save`)}
        </Button>
        <Typography sx={{ width: "100%", textAlign: "right" }}>
          {t("wizard.summary.saveConfiguration", `Save configuration in a file`)}
        </Typography>
      </ButtonsContainer>
    </StepWrapper>
  );
}

export default Summary;
