import styled from "styled-components";

type TagWrapperProps = {
  backgroundColor: string;
  borderColor: string;
  pointer?: boolean;
  hasIcon?: boolean;
  onClick?: () => void;
};

export const TagWrapper = styled.div<TagWrapperProps>`
  position: relative;
  width: 150px;
  height: 29.21px;
  background-color: rgb(var(--white-rgb));
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 2px solid ${props => props.borderColor};
  background-color: ${props => props.backgroundColor};
  padding: 0 6px;
  font-size: 15px;

  span {
    font-weight: 500;
  }

  svg {
    position: absolute;
    right: 0px;
    scale: 0.85;
  }

  &:hover {
    cursor: ${p => (p.onClick || p.pointer) && "pointer"};
  }
`;

export const StyledTextFitWrapper = styled.div<{ hasIcon: boolean }>`
  width: 100%;
  margin-top: -0.5px;
  height: calc(100% - 1px);
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  line-height: 12px;
  ${props =>
    props.hasIcon &&
    `
      width: calc(100% - 20px);
      margin-left: -20px;
    `}
`;
