import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button";
import Typography from "../../../../components/Typography";
import { StyledDownload, StyledLoaderBackDrop } from "./styled";
import { ICONS } from "./utils";
import { InlineLoader } from "../../../../components/Loader";
import { useSettings } from "../../../../context/Settings";

type Props = {
  icon: "pdf" | "xls";
  title: string;
  onDownloadClick: () => void;
  disabled: boolean;
};

function Download({ icon, title, onDownloadClick, disabled }: Props) {
  const Icon = ICONS[icon];
  const { t } = useTranslation();
  const { disableExport, disableExportText } = useSettings();

  return (
    <StyledDownload>
      <Icon />
      <div>
        <Typography size="22px" lh="37px">
          {title}
        </Typography>
      </div>
      <Button
        onClick={onDownloadClick}
        disabled={disabled || disableExport}
        title={disableExport ? disableExportText : undefined}
      >
        {t("wizard.summary.download", "DOWNLOAD")}
      </Button>
      {disabled && (
        <StyledLoaderBackDrop>
          <InlineLoader />
        </StyledLoaderBackDrop>
      )}
    </StyledDownload>
  );
}

export default Download;
