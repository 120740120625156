import styled from "styled-components";

type IntroContentWrapperProp = {
  isFullScreen: boolean;
};

export const IntroWizardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const IntroImageWrapper = styled.div`
  width: 60%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IntroContentWrapper = styled.div<IntroContentWrapperProp>`
  position: absolute;
  z-index: 1;
  width: ${props => (props.isFullScreen ? "100%" : "40%")};
  right: 0;
  height: 100%;
  min-height: 768px;
  background: var(--white);
  box-shadow: var(--box-shadow);
  overflow: auto;
  padding: ${props => (props.isFullScreen ? "30px 145px" : "30px 40px")};
  display: flex;
  flex-direction: column;
`;

export const Img = styled.img`
  height: 100%;
  width: auto;
  max-width: 100%;
`;

export const IntroContent = styled.div`
  overflow: auto;
  a {
    color: var(--link-color);
  }
`;

export const IntroNavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  align-items: flex-start;
  gap: 10px;
  flex: 1;

  & span {
    padding: 0 10px;
    font-size: 35px;
  }

  & svg {
    margin: 0 14px 0 8px;
  }

  & u {
    line-height: 16px;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const MoreLessButton = styled.a`
  font-size: 13px;
  cursor: pointer;
  color: var(--link-color);
  text-decoration: underline;
`;
