import { ChangeEvent } from "react";
import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { showOnlyImportantConnectionsVar, fieldsActivationStateVar } from "../../../apollo/state";
import Box from "../../../components/Box";
import Button from "../../../components/Button";
import SectionTitle from "../../../components/SectionTitle";
import Typography from "../../../components/Typography";
import Guide from "../Guide";
import { ButtonsContainer } from "../styled";
import StepWrapper from "../../../components/StepWrapper";
import { STEP_URLS } from "../../../constants";
import { FieldErrors, HydratedField } from "../types";
import { WarningIcon } from "../../../components/icons";
import Checkbox from "../../../components/Checkbox";
import { useNavigateWithState } from "../../../utils/useNavigateWithState";
import { MeasuresSubTitle, KpisSubTitle, AttributesWithFieldsList } from "./components";
import { getSelectedElementsCount } from "../../../utils";
import { useAttributesFromFields } from "../hooks/useAttributesFromFields";

type Props = {
  fieldErrors: FieldErrors;
  fields: HydratedField[];
};

function renderWarning(msg: string) {
  return (
    <Typography
      size="13px"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "5px",
        marginTop: "5px",
      }}
    >
      <WarningIcon />
      {msg}
    </Typography>
  );
}

function Preview({ fieldErrors, fields }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigateWithState();

  const measuresFromSelectedFields = useAttributesFromFields(fields, "measures");
  const kpisFromSelectedFields = useAttributesFromFields(fields, "KPIs");

  const showOnlyImportantConnections = useReactiveVar(showOnlyImportantConnectionsVar);
  const { partiallyActivatedFieldIdsMap } = useReactiveVar(fieldsActivationStateVar);

  const partiallyActivatedFieldsCount = getSelectedElementsCount(partiallyActivatedFieldIdsMap);

  return (
    <StepWrapper
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <SectionTitle>{t("wizard.preview.preview", `Preview`)}</SectionTitle>
      <Box sx={{ overflow: "auto", height: "100%" }}>
        <MeasuresSubTitle
          count={measuresFromSelectedFields.length}
          fieldsCount={partiallyActivatedFieldsCount}
        />
        <AttributesWithFieldsList attributesWithFields={measuresFromSelectedFields} />
        <KpisSubTitle
          count={kpisFromSelectedFields.length}
          fieldsCount={partiallyActivatedFieldsCount}
        />
        <AttributesWithFieldsList attributesWithFields={kpisFromSelectedFields} />
      </Box>
      <ButtonsContainer sx={{ flexWrap: "wrap" }}>
        <Box as="label" sx={{ cursor: "pointer", width: "100%", textAlign: "center" }}>
          <Checkbox
            checked={showOnlyImportantConnections}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              showOnlyImportantConnectionsVar(e.currentTarget.checked);
            }}
          />
          <Typography as="span" size="13px">
            {t("wizard.preview.importantConnections", `Show only important connections`)}
          </Typography>
        </Box>
        <Button
          onClick={() => {
            navigate(STEP_URLS.pickFields);
          }}
        >
          {t("wizard.preview.addFields", `Add fields`)}
        </Button>
        <Button
          onClick={() => {
            navigate(STEP_URLS.summary);
          }}
        >
          {t("wizard.preview.ready", `Ready`)}
        </Button>
      </ButtonsContainer>
      <Guide />
      {fieldErrors.importantFieldNotSelected.length > 0 &&
        renderWarning(
          t("wizard.preview.importantFieldNotSelected", `Important field is not selected`)
        )}
      {fieldErrors.importantConnectionNotSelected.length > 0 &&
        renderWarning(
          t(
            "wizard.preview.importantConnectionNotSelected",
            `Field with important connection is not selected`
          )
        )}
    </StepWrapper>
  );
}

export default Preview;
