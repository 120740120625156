import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { selectedKpisVar, selectedMeasuresVar } from "../../../apollo/state";
import Box from "../../../components/Box";
import Button from "../../../components/Button";
import Tag from "../../../components/Tag";
import Typography from "../../../components/Typography";
import { STEP_URLS } from "../../../constants";
import { getSelectedElementsCount } from "../../../utils";
import Guide from "../Guide";
import { ButtonsContainer } from "../styled";
import { createAttributeSelectionFunc, createAttributesClearFunc } from "./utils";
import StepWrapper from "../../../components/StepWrapper";
import { HydratedField, HydratedSubField, ProcessedDtoSubField } from "../types";
import { useNavigateField, hasAllFieldTypeSelected } from "../utils";
import { ExclamationIcon, SimpleInfoIcon } from "./icons";
import { useNavigateWithState } from "../../../utils/useNavigateWithState";
import { useSettings } from "../../../context/Settings";
import { LockedInfo } from "../../../components/LockedInfo";
import { useState } from "react";
import { FieldAttributes } from "../../../components/FieldAttributes";

type Props = {
  currentField?: HydratedField;
  currentSubField?: HydratedSubField;
  hydratedFields: HydratedField[];
  subFieldsMap: Record<string, ProcessedDtoSubField>;
  fieldsMap: Record<string, HydratedField>;
};

function PickKeysForSubField(props: Props) {
  const { currentField, currentSubField, hydratedFields, subFieldsMap, fieldsMap } = props;
  const { t } = useTranslation();
  const navigate = useNavigateWithState();
  const { lockedFieldText } = useSettings();
  const selectedKPIs = useReactiveVar(selectedKpisVar);
  const selectedMeasures = useReactiveVar(selectedMeasuresVar);
  const navigateField = useNavigateField();
  const navigateSubField = useNavigateField(true);

  const selectedKPIsCount = getSelectedElementsCount(selectedKPIs);
  const isKPIsSelected = selectedKPIsCount > 0;
  const oneFieldPerTypeIsNotSelected = !hasAllFieldTypeSelected(hydratedFields);

  const [isDirty, setIsDirty] = useState(false);

  if (!(currentField && currentSubField)) {
    return null;
  }

  const handleKpiSelection = createAttributeSelectionFunc(
    setIsDirty,
    selectedKPIs,
    selectedKpisVar
  );
  const handleMeasureSelection = createAttributeSelectionFunc(
    setIsDirty,
    selectedMeasures,
    selectedMeasuresVar
  );

  const clearKPIs = createAttributesClearFunc(setIsDirty, currentField.KPIs, selectedKpisVar);

  const clearMeasures = createAttributesClearFunc(
    setIsDirty,
    currentField.measures,
    selectedMeasuresVar
  );

  return (
    <StepWrapper>
      <Box sx={{ height: "100%", overflow: "auto" }}>
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
          m="0 0 13px"
        >
          <Tag
            type={{ id: "", name: "", baseColor: "255, 255, 255" }}
            weight={1}
            name={currentSubField.area}
            hasMeasureSelected={false}
            hasKpiSelected={false}
            wrapperProps={{
              backgroundColor: "rgb(var(--white-rgb))",
              borderColor: "var(--grey)",
            }}
          />
          <Tag {...currentField} onClick={() => navigateField(currentField.id)} />
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            gap: "5px 15px",
            marginBottom: "20px",
            marginLeft: "50px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              left: "-24px",
              top: "-7px",
              width: "20px",
              height: "22px",
              border: "1px solid #404040",
              borderTop: "0",
              borderRight: "0",
            }}
          />
          <Tag {...currentField} {...currentSubField} />
          <Typography
            size="14px"
            dangerouslySetInnerHTML={{
              __html: currentSubField.description,
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: "30px",
          }}
        >
          <div style={{ marginRight: "8px", scale: "0.7" }}>
            <SimpleInfoIcon />
          </div>

          <Typography size="14px">
            {t(
              "wizard.pickKeysForSubField.fieldInfoText",
              "Field of action activated if at least one measure and one indicator is selected."
            )}
          </Typography>
        </Box>
        {currentField.locked ? (
          <LockedInfo>{lockedFieldText}</LockedInfo>
        ) : (
          <>
            <FieldAttributes
              title={t("wizard.pickKeysForSubField.kpis", "KPIs")}
              items={currentField.KPIs}
              onChange={handleKpiSelection}
              clearSelection={clearKPIs}
              onSubFieldClicked={navigateSubField}
              subFieldsMap={subFieldsMap}
              fieldsMap={fieldsMap}
              currentSubField={currentSubField}
            />
            <FieldAttributes
              title={t("wizard.pickKeysForSubField.measures", "Measures")}
              items={currentField.measures}
              onChange={handleMeasureSelection}
              clearSelection={clearMeasures}
              onSubFieldClicked={navigateSubField}
              subFieldsMap={subFieldsMap}
              fieldsMap={fieldsMap}
              currentSubField={currentSubField}
            />
          </>
        )}
      </Box>
      <ButtonsContainer>
        <Button
          onClick={() => {
            navigateField(currentField.id);
          }}
        >
          {t("wizard.pickKeysForSubField.back", "Back")}
        </Button>
        <Button
          disabled={!isDirty}
          onClick={() => {
            navigateField(currentField.id);
          }}
        >
          {t("wizard.pickKeysForSubField.next", "Save selection")}
        </Button>
        <Button
          disabled={!isKPIsSelected || oneFieldPerTypeIsNotSelected}
          onClick={() => {
            navigate(STEP_URLS.preview);
          }}
        >
          {t("wizard.pickKeysForSubField.preview", "Preview")}
        </Button>
        {currentField.locked && <LockedInfo>{lockedFieldText}</LockedInfo>}
      </ButtonsContainer>
      <Guide />
      {oneFieldPerTypeIsNotSelected && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            width: "100%",
            margin: "0 0 -10px",
            padding: "10px 0 0",
            background: "var(--white)",
          }}
        >
          <ExclamationIcon />
          <Typography size="13px">
            {t(
              "wizard.pickKeysForSubField.oneFieldPerTypeIsNotSelected",
              "One field per type for preview and Scorecard required"
            )}
          </Typography>
        </Box>
      )}
    </StepWrapper>
  );
}

export default PickKeysForSubField;
