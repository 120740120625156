import { useEffect, useMemo } from "react";
import { uniq, intersection } from "lodash-es";
import { DtoKpi, DtoMeasure, ProcessedDtoSubField } from "../types";
import { useReactiveVar } from "@apollo/client";
import {
  fieldsActivationStateVar,
  selectedKpisVar,
  selectedMeasuresVar,
} from "../../../apollo/state";
import { SelectionMap } from "../../../@types";

function getFieldsIdByActivatedAttribute(
  subFieldsMap: Record<string, ProcessedDtoSubField>,
  allAttrs: DtoKpi[] | DtoMeasure[],
  selectedAttrsIds: SelectionMap
) {
  return allAttrs.reduce<SelectionMap>((acc, attr) => {
    if (selectedAttrsIds[attr.id]) {
      attr.subFields.forEach(sfId => {
        const subField = subFieldsMap[sfId];
        if (subField?.fieldId) {
          acc[subField.fieldId] = true;
        }
      });
    }
    return acc;
  }, {});
}

function createSelectionMapFromArray(array: any[]) {
  return array.reduce<SelectionMap>((acc, val) => {
    acc[val] = true;
    return acc;
  }, {});
}

export function useFieldsActivation(
  subFieldsMap: Record<string, ProcessedDtoSubField>,
  kpis: DtoKpi[],
  measures: DtoMeasure[]
) {
  const selectedKpis = useReactiveVar(selectedKpisVar);
  const selectedMeasures = useReactiveVar(selectedMeasuresVar);

  const data = useMemo(() => {
    const fieldIdsWithActivatedKpisMap = getFieldsIdByActivatedAttribute(
      subFieldsMap,
      kpis,
      selectedKpis
    );
    const fieldIdsWithActivatedMeasuresMap = getFieldsIdByActivatedAttribute(
      subFieldsMap,
      measures,
      selectedMeasures
    );
    const fieldIdsWithActivatedKpis = Object.keys(fieldIdsWithActivatedKpisMap);
    const fieldIdsWithActivatedMeasures = Object.keys(fieldIdsWithActivatedMeasuresMap);

    const partiallyActivatedFieldIds = uniq([
      ...fieldIdsWithActivatedKpis,
      ...fieldIdsWithActivatedMeasures,
    ]);
    const fullyActivatedFieldIds = intersection(
      fieldIdsWithActivatedKpis,
      fieldIdsWithActivatedMeasures
    );

    const partiallyActivatedFieldIdsMap = createSelectionMapFromArray(partiallyActivatedFieldIds);
    const fullyActivatedFieldIdsMap = createSelectionMapFromArray(fullyActivatedFieldIds);

    return {
      partiallyActivatedFieldIdsMap,
      fullyActivatedFieldIdsMap,
    };
  }, [subFieldsMap, kpis, measures, selectedKpis, selectedMeasures]);

  useEffect(() => {
    fieldsActivationStateVar(data);
  }, [data]);

  return data;
}
