import { forwardRef } from "react";
import styled from "styled-components";
import { Ref } from "../../@types";
import Box, { BoxProps } from "../Box";

type WeightProps = {
  value?: number;
} & BoxProps;

const StyledWrapper = styled(Box)`
  position: relative;
`;

const StyledLine = styled.div`
  width: 100%;
  height: 2px;
  background: var(--form-input-color);
`;

const StyledIndicator = styled.div<{ value: number }>`
  left: ${s => (s.value ? `calc(${s.value}% - 6px)` : "")};
  top: -5px;
  height: 12px;
  width: 12px;
  border-radius: 100%;
  background: var(--form-input-color);
  position: absolute;
`;

const WeightIndicator = forwardRef<Ref, WeightProps>(({ value, ...rest }, ref) => (
  <StyledWrapper {...rest} ref={ref}>
    {value && <StyledIndicator value={value} />}
    <StyledLine />
  </StyledWrapper>
));

export default WeightIndicator;
