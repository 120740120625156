import { getFieldOpacity } from "../../utils";
import { TagWrapper, StyledTextFitWrapper } from "./styled";
import { DtoFieldType } from "../../routes/RadarWizard/types";
import { getSelectionIcon } from "../Radar/components/Tag/icons";
import { GenericObject } from "../../@types";
import useFitText from "./useFitText";

type TagProps = {
  name: string;
  type: DtoFieldType;
  weight: number;
  pointer?: boolean;
  onClick?: () => void;
  hasMeasureSelected: boolean;
  hasKpiSelected: boolean;
  wrapperProps?: GenericObject;
};

function Tag({
  name,
  type,
  weight,
  pointer,
  onClick,
  hasMeasureSelected,
  hasKpiSelected,
  wrapperProps = {},
}: TagProps) {
  const opacity = getFieldOpacity(weight);
  const hasIcon = hasKpiSelected || hasMeasureSelected;
  const { fontSize, ref } = useFitText({ hasIcon });

  const backgroundColor = `rgba(${type.baseColor}, ${opacity})`;
  const borderColor = `rgb(${type.baseColor})`;
  return (
    <TagWrapper
      onClick={onClick}
      backgroundColor={backgroundColor}
      pointer={pointer}
      borderColor={borderColor}
      hasIcon={hasIcon}
      {...wrapperProps}
    >
      <StyledTextFitWrapper hasIcon={hasIcon} ref={ref} style={{ fontSize }}>
        <span dangerouslySetInnerHTML={{ __html: name }} />
      </StyledTextFitWrapper>
      {getSelectionIcon(hasKpiSelected, hasMeasureSelected)}
    </TagWrapper>
  );
}

export default Tag;
