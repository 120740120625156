import {
  FieldsConnectionMap,
  HydratedSubField,
  KpiBySubFieldMap,
  MatrixObject,
  MeasureBySubFieldMap,
  ProcessedDtoSubField,
} from "../../types";
import { useMemo } from "react";
import { EMPTY_MATRIX } from "../constants";
import { getManyToManyConnections, getStaticTopConnections } from "../../utils";
import { SelectionMap } from "../../../../@types";

export function useStaticFieldsConnectionsMap(
  matrix: MatrixObject<number>,
  connectionLabels: MatrixObject<string>
) {
  return useMemo<FieldsConnectionMap>(() => {
    if (matrix !== EMPTY_MATRIX && connectionLabels !== EMPTY_MATRIX) {
      return getStaticTopConnections(matrix, connectionLabels);
    }

    return EMPTY_MATRIX;
  }, [matrix, connectionLabels]);
}

export function useManyToManyFieldsConnectionsMap(
  shouldUseManyToManyConnections: boolean,
  matrix: MatrixObject<number>,
  connectionLabels: MatrixObject<string>,
  selectedFields: SelectionMap,
  staticFieldsConnectionsMap: FieldsConnectionMap
) {
  return useMemo<FieldsConnectionMap>(() => {
    if (
      shouldUseManyToManyConnections &&
      matrix !== EMPTY_MATRIX &&
      connectionLabels !== EMPTY_MATRIX
    ) {
      return getManyToManyConnections(
        matrix,
        connectionLabels,
        selectedFields,
        staticFieldsConnectionsMap
      );
    }

    return EMPTY_MATRIX;
  }, [
    matrix,
    connectionLabels,
    selectedFields,
    staticFieldsConnectionsMap,
    shouldUseManyToManyConnections,
  ]);
}

export function useHydratedSubFields(
  subFields: ProcessedDtoSubField[],
  measuresBySubFieldMap: MeasureBySubFieldMap,
  kpiBySubFieldMap: KpiBySubFieldMap,
  selectedKpis: SelectionMap,
  selectedMeasures: SelectionMap
) {
  return useMemo(() => {
    return subFields.map<HydratedSubField>(sf => {
      return {
        ...sf,
        hasMeasureSelected: !!measuresBySubFieldMap[sf.id]?.some(
          measure => selectedMeasures[measure.id]
        ),
        hasKpiSelected: !!kpiBySubFieldMap[sf.id]?.some(kpi => selectedKpis[kpi.id]),
      };
    });
  }, [subFields, measuresBySubFieldMap, kpiBySubFieldMap, selectedKpis, selectedMeasures]);
}
