import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  fieldsActivationStateVar,
  selectedKpisVar,
  selectedMeasuresVar,
} from "../../../apollo/state";
import Box from "../../../components/Box";
import Typography from "../../../components/Typography";
import { getSelectedElementsCount } from "../../../utils";
import { ExclamationIcon } from "../PickSubFields/icons";
import { STEP_URLS } from "../../../constants";
import { useUrlMatching } from "../utils";

const TOO_MANY_FIELDS_THRESHOLD_COUNT = 6;

function Guide() {
  const { t } = useTranslation();
  const { fullyActivatedFieldIdsMap, partiallyActivatedFieldIdsMap } =
    useReactiveVar(fieldsActivationStateVar);

  const selectedKpis = useReactiveVar(selectedKpisVar);
  const selectedMeasures = useReactiveVar(selectedMeasuresVar);

  const selectedKPIsCount = getSelectedElementsCount(selectedKpis);
  const selectedMeasuresCount = getSelectedElementsCount(selectedMeasures);
  const partiallyActivatedFieldsCount = getSelectedElementsCount(partiallyActivatedFieldIdsMap);
  const activatedFieldsCount = getSelectedElementsCount(fullyActivatedFieldIdsMap);

  const isKPIorMeasureSelected = selectedKPIsCount > 0 || selectedMeasuresCount > 0;
  const isTooManyFieldsSelected = activatedFieldsCount >= TOO_MANY_FIELDS_THRESHOLD_COUNT;
  const isWarningFieldsPages = useUrlMatching([STEP_URLS.preview, STEP_URLS.pickKeysForSubField]);
  const isFieldsSelectionPages = useUrlMatching([STEP_URLS.pickFields]);

  return (
    <Typography size="13px" ta="center">
      {isKPIorMeasureSelected && (
        <>
          {t(
            "wizard.guide.selectedKpisAndFields",
            `{{kpiCount}} {{kpiLabel}} & {{measureCount}} {{measureLabel}} from {{partiallyActivatedFieldsCount}} {{fieldLabel2}} selected. {{fieldsActivatedCount}} {{fieldLabel}} activated`,
            {
              kpiCount: selectedKPIsCount,
              kpiLabel:
                selectedKPIsCount > 1
                  ? t("wizard.guide.kpis", "KPIs")
                  : t("wizard.guide.kpi", "KPI"),
              measureCount: selectedMeasuresCount,
              measureLabel:
                selectedMeasuresCount > 1
                  ? t("wizard.guide.measures", "Measures")
                  : t("wizard.guide.measure", "Measure"),
              fieldsActivatedCount: activatedFieldsCount,
              partiallyActivatedFieldsCount,
              fieldLabel:
                activatedFieldsCount > 1
                  ? t("wizard.guide.fields", "fields")
                  : t("wizard.guide.field", "field"),
              fieldLabel2:
                partiallyActivatedFieldsCount > 1
                  ? t("wizard.guide.fields2", "fields")
                  : t("wizard.guide.field", "field"),
            }
          )}
          {isTooManyFieldsSelected && isWarningFieldsPages && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              <ExclamationIcon />
              <Typography lh="16px">
                {t("wizard.guide.fieldsFocusRecommended", `A focus on 3-5 fields is recommended`)}
              </Typography>
            </Box>
          )}
        </>
      )}
      {isFieldsSelectionPages && (
        <div>
          {t(
            "wizard.guide.noFieldsSelected",
            "Select fields of action in the ESG Radar on the left or in the list at the top."
          )}
        </div>
      )}
    </Typography>
  );
}

export default Guide;
