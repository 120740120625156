import { ReactNode } from "react";
import { PresetWrapper, StyledStepWrapper } from "./styled";
import { BoxProps } from "../Box";
import { useReactiveVar } from "@apollo/client";
import { selectedPresetNameVar } from "../../apollo/state";
import { useTranslation } from "react-i18next";

type Props = {
  children?: ReactNode;
  hidePreset?: boolean;
} & BoxProps;

function StepWrapper({ children, hidePreset, ...restProps }: Props) {
  const { t } = useTranslation();
  const selectedPresetName = useReactiveVar(selectedPresetNameVar);

  return (
    <StyledStepWrapper {...restProps}>
      {!hidePreset && (
        <PresetWrapper>
          {t("wizard.industryTitle", "Industry")}: {selectedPresetName}
        </PresetWrapper>
      )}
      {children}
    </StyledStepWrapper>
  );
}

export default StepWrapper;
