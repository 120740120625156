// This hook is based on https://www.npmjs.com/package/use-fit-text
// But with some tweaks for current project for better performance in favour of flexibility
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

export type TOptions = {
  maxFontSize?: number;
  minFontSize?: number;
  resolution?: number;
  hasIcon?: boolean;
};

const useFitText = ({
  maxFontSize = 100,
  minFontSize = 20,
  resolution = 5,
  hasIcon = false,
}: TOptions = {}) => {
  const initState = useCallback(() => {
    return {
      calcKey: 0,
      fontSize: maxFontSize,
      fontSizePrev: minFontSize,
      fontSizeMax: maxFontSize,
      fontSizeMin: minFontSize,
    };
  }, [maxFontSize, minFontSize]);

  const ref = useRef<HTMLDivElement>(null);
  const innerHtmlPrevRef = useRef<string>();
  const isCalculatingRef = useRef(false);
  const [state, setState] = useState(initState);
  const { calcKey, fontSize, fontSizeMax, fontSizeMin, fontSizePrev } = state;

  useEffect(() => {
    setState({
      ...initState(),
      calcKey: calcKey + 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasIcon]);

  // Recalculate when the div contents change
  const innerHtml = ref.current && ref.current.innerHTML;
  useEffect(() => {
    if (calcKey === 0 || isCalculatingRef.current) {
      return;
    }
    if (innerHtml !== innerHtmlPrevRef.current) {
      setState({
        ...initState(),
        calcKey: calcKey + 1,
      });
    }
    // @ts-ignore
    innerHtmlPrevRef.current = innerHtml;
  }, [calcKey, initState, innerHtml, hasIcon]);

  // Check overflow and resize font
  useLayoutEffect(() => {
    // Don't start calculating font size until the `resizeKey` is incremented
    // above in the `ResizeObserver` callback. This avoids an extra resize
    // on initialization.
    if (calcKey === 0) {
      return;
    }

    const isWithinResolution = Math.abs(fontSize - fontSizePrev) <= resolution;
    const isOverflow =
      !!ref.current &&
      (ref.current.scrollHeight > ref.current.offsetHeight ||
        ref.current.scrollWidth > ref.current.offsetWidth);
    const isFailed = isOverflow && fontSize === fontSizePrev;
    const isAsc = fontSize > fontSizePrev;

    // Return if the font size has been adjusted "enough" (change within `resolution`)
    // reduce font size by one increment if it's overflowing.
    if (isWithinResolution) {
      if (isFailed) {
        isCalculatingRef.current = false;
      } else if (isOverflow) {
        setState({
          fontSize: isAsc ? fontSizePrev : fontSizeMin,
          fontSizeMax,
          fontSizeMin,
          fontSizePrev,
          calcKey,
        });
      } else {
        isCalculatingRef.current = false;
      }
      return;
    }

    // Binary search to adjust font size
    let delta: number;
    let newMax = fontSizeMax;
    let newMin = fontSizeMin;
    if (isOverflow) {
      delta = isAsc ? fontSizePrev - fontSize : fontSizeMin - fontSize;
      newMax = Math.min(fontSizeMax, fontSize);
    } else {
      delta = isAsc ? fontSizeMax - fontSize : fontSizePrev - fontSize;
      newMin = Math.max(fontSizeMin, fontSize);
    }
    setState({
      calcKey,
      fontSize: fontSize + delta / 2,
      fontSizeMax: newMax,
      fontSizeMin: newMin,
      fontSizePrev: fontSize,
    });
  }, [calcKey, fontSize, fontSizeMax, fontSizeMin, fontSizePrev, ref, hasIcon, resolution]);

  return { fontSize: `${fontSize}%`, ref };
};

export default useFitText;
