import LoginWelcome from "../Welcome/components/LoginWelcome";
import { useUrlMatching } from "../utils";
import { STEP_URLS } from "../../../constants";
import { Login } from "./components/Login";
import { Signup } from "./components/Signup";
import { ChangePassword } from "./components/ChangePassword";
import { getCustomizationParams } from "../../../utils";

const customizationParams = getCustomizationParams();

function useAuthComponent() {
  const isChangePassword = useUrlMatching([STEP_URLS.changePassword]);
  const isSignup = useUrlMatching([STEP_URLS.signup]);

  if (isChangePassword) {
    return ChangePassword;
  } else if (isSignup) {
    return Signup;
  }

  return Login;
}

function UserAuth() {
  const AuthComponent = useAuthComponent();

  return (
    <LoginWelcome hidePreset={true}>
      <AuthComponent sheetId={customizationParams.sheetId} />
    </LoginWelcome>
  );
}

export default UserAuth;
