import { GenericObject, IDable, SelectionMap } from "../../../@types";

export function createAttributeSelectionFunc(
  setIsDirty: (val: boolean) => void,
  stateVar: SelectionMap,
  setStateVar: (newVal: SelectionMap) => SelectionMap
) {
  return (id: string, checked: boolean) => {
    setIsDirty(true);
    const newState = { ...stateVar, [id]: checked };
    setStateVar(newState);
  };
}

export function createAttributesClearFunc(
  setIsDirty: (val: boolean) => void,
  fieldAttributes: IDable[],
  setStateVar: (newVal: SelectionMap) => SelectionMap
) {
  return () => {
    setIsDirty(false);
    const newState = fieldAttributes.reduce<GenericObject<boolean>>((acc, item) => {
      acc[item.id] = false;
      return acc;
    }, {});
    setStateVar(newState);
  };
}
