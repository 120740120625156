import styled from "styled-components";
import Box from "../../components/Box";

export const RadarWizardWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const RadarWrapper = styled.div`
  width: 60%;
  height: 100%;
`;

export const WizardWrapper = styled.div`
  overflow: hidden;
`;

export const ButtonsContainer = styled(Box)`
  width: 100%;
  margin: 20px 0 20px;
  padding: 20px 0 0;
  border-top: 1px solid var(--grey);
  display: flex;
  justify-content: center;
  gap: 10px;
`;
