import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  margin-bottom: 50px;
`;

export const AttributesBox = styled.div`
  font-size: 13px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  padding: 15px 0px;
  position: relative;
`;

export const TitleWrapper = styled.div`
  position: absolute;
  top: -9px;
  left: 35px;
  padding: 0 5px;
  background-color: #fff;
`;

export const DeselectButtonContainer = styled.div`
  position: absolute;
  top: -14px;
  left: 140px;
  padding: 0 5px;
  background-color: #fff;
`;

export const DeselectButton = styled.button`
  background-color: #fff;
  border: 2px solid #c72a34;
  padding: 4px 7px;
  color: #c72a34;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    border-color: #a70a14;
    color: #a70a14;
  }
  &:disabled {
    background: none;
    color: var(--form-input-disabled-color);
    border-color: var(--form-input-disabled-color);
    cursor: unset;
  }
`;

export const Attribute = styled.div`
  display: flex;
  padding: 7px 15px;
  border-bottom: 1px solid #d0d0d0;
  &:last-child {
    border-bottom: 0;
  }
`;

export const Header = styled(Attribute)`
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 0;
  padding: 0 15px;
`;

export const Name = styled.div`
  width: 50%;
  margin-top: -2px;
`;

export const Relevance = styled.div`
  width: 20%;
  text-align: center;
`;

export const SubFields = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;
