import { useTranslation } from "react-i18next";
import Box from "../../../../components/Box";
import { LoginInputWrapper, LoginLabel, LoginInput, LoginWrapper, AuthFailed } from "./../styled";
import { useState, ChangeEvent, KeyboardEvent, ReactNode } from "react";

export function CommonPasswords({
  isMutationFailed,
  children,
  errorMessage,
  onValueChanged,
  onEnterPressed,
  userEmail,
}: {
  children: (props: { shouldDisableControls: boolean }) => ReactNode;
  errorMessage: string;
  isMutationFailed: boolean;
  onValueChanged: (value: string) => void;
  onEnterPressed: () => void;
  userEmail?: string;
}) {
  const { t } = useTranslation();
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);

  const setPasswordsMatch = (p1: string, p2: string) => {
    if (p1 && p2) {
      setIsPasswordMatch(p1 === p2);
    }
  };

  const handlePassword1Change = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setPassword1(value);
    setPasswordsMatch(value, password2);
    onValueChanged(value);
  };

  const handlePassword2Change = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setPassword2(value);
    setPasswordsMatch(password1, value);
    onValueChanged(value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onEnterPressed();
    }
  };

  return (
    <>
      <LoginWrapper>
        {userEmail && (
          <span>
            {t("wizard.welcome.setPasswordFor", "Please set your password for login")}{" "}
            <strong>{userEmail}</strong>
          </span>
        )}
        <LoginInputWrapper>
          <LoginLabel>{t("wizard.welcome.password", "Password")}</LoginLabel>
          <LoginInput
            name="password1"
            type="password"
            value={password1}
            onChange={handlePassword1Change}
            onKeyDown={handleKeyDown}
          />
        </LoginInputWrapper>
        <LoginInputWrapper>
          <LoginLabel>{t("wizard.welcome.repeatPassword", "Repeat password")}</LoginLabel>
          <LoginInput
            name="password2"
            type="password"
            value={password2}
            onChange={handlePassword2Change}
            onKeyDown={handleKeyDown}
          />
        </LoginInputWrapper>
        {isMutationFailed && <AuthFailed>{errorMessage}</AuthFailed>}
        {!isPasswordMatch && (
          <AuthFailed>
            {t("wizard.welcome.passwordsDoesntMatch", "Passwords should be same")}
          </AuthFailed>
        )}
      </LoginWrapper>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <div>
          {children({
            shouldDisableControls:
              !(password1 && password2) || !isPasswordMatch || password1.length < 6,
          })}
        </div>
      </Box>
    </>
  );
}
