import { useTranslation } from "react-i18next";
import SectionTitle from "../../../components/SectionTitle";
import { ButtonsContainer } from "../styled";
import StepWrapper from "../../../components/StepWrapper";
import { PickFieldsProps } from "./types";
import { useReactiveVar } from "@apollo/client";
import { fieldsActivationStateVar, selectedKpisVar } from "../../../apollo/state";
import { getPickFields } from "./utils";
import Box from "../../../components/Box";
import Button from "../../../components/Button";
import { STEP_URLS } from "../../../constants";
import FieldsContainer from "./FieldsContainer";
import { getSelectedElementsCount } from "../../../utils";
import { hasAllFieldTypeSelected } from "../utils";
import Guide from "../Guide";
import { useNavigateWithState } from "../../../utils/useNavigateWithState";
import Typography from "../../../components/Typography";

function PickFields({ hydratedFields, onTagClicked, fieldTypes }: PickFieldsProps) {
  const { t } = useTranslation();
  const navigate = useNavigateWithState();
  const { fullyActivatedFieldIdsMap } = useReactiveVar(fieldsActivationStateVar);
  const groupedFields = getPickFields(hydratedFields);

  const selectedKPIs = useReactiveVar(selectedKpisVar);
  const selectedKPIsCount = getSelectedElementsCount(selectedKPIs);
  const selectedFieldsCount = getSelectedElementsCount(fullyActivatedFieldIdsMap);
  const isKPIsSelected = selectedKPIsCount > 0;
  const oneFieldPerTypeIsNotSelected = !hasAllFieldTypeSelected(hydratedFields);

  function handleBackClick() {
    navigate(STEP_URLS.weighting);
  }

  function handlePreviewClick() {
    navigate(STEP_URLS.preview);
  }

  return (
    <StepWrapper>
      <SectionTitle>{t("wizard.pickFields.title", "select areas of action")}</SectionTitle>
      <Box
        m="0 0 40px"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
          overflow: "auto",
          margin: "0 -10px",
        }}
      >
        {fieldTypes
          .filter(fType => !!groupedFields[fType.id])
          .map(fType => (
            <Box key={`groupedFields-${fType.id}`} m="0 0 15px">
              <Typography size="17px" p="0 0 0 11px">
                {fType.name}
              </Typography>
              <FieldsContainer fields={groupedFields[fType.id]} onTagClicked={onTagClicked} />
            </Box>
          ))}
      </Box>
      <ButtonsContainer>
        <Button onClick={handleBackClick}>{t("wizard.pickFields.back", "BACK")}</Button>
        {!!selectedFieldsCount && (
          <Button
            disabled={!isKPIsSelected || oneFieldPerTypeIsNotSelected}
            onClick={handlePreviewClick}
          >
            {t("wizard.pickFields.preview", "PREVIEW")}
          </Button>
        )}
      </ButtonsContainer>
      <Guide />
    </StepWrapper>
  );
}

export default PickFields;
