import { Position } from "./types";
import { isLeftQuadrant, isTopQuadrant } from "../../utils";

const START_ANGLE = 27;
const RADIUS = 50; //50%
const MAX_ITEMS_ON_THE_QUADRANT = 10;
const ANGLE_OFFSET = (90 - START_ANGLE) / MAX_ITEMS_ON_THE_QUADRANT;
const VERTICAL_OFFSET = 4.2; // Space between items

const RIGHT_QUADRANT_X_OFFSET = 31.5;
const TOP_QUADRANT_Y_OFFSET = 8;
const BOTTOM_QUADRANT_Y_OFFSET = 8;

const getInitialAngle = (itemPosition: number) => {
  return START_ANGLE + ANGLE_OFFSET * itemPosition;
};

const getAbsoluteXPosition = (angle: number) => RADIUS * Math.sin((Math.PI * 2 * angle) / 360);

export const calculateItemPosition = (itemPosition: number, quadrant: number): Position => {
  let x: number;
  let y: number;
  let angle: number;

  if (isTopQuadrant(quadrant)) {
    angle = getInitialAngle(itemPosition);
    y = TOP_QUADRANT_Y_OFFSET + VERTICAL_OFFSET * itemPosition;
  } else {
    angle = getInitialAngle(MAX_ITEMS_ON_THE_QUADRANT - itemPosition);
    y = RADIUS + BOTTOM_QUADRANT_Y_OFFSET + VERTICAL_OFFSET * itemPosition;
  }

  const absX = getAbsoluteXPosition(angle);

  if (isLeftQuadrant(quadrant, null)) {
    x = RADIUS - absX;
  } else {
    x = RIGHT_QUADRANT_X_OFFSET + absX;
  }

  return { x, y };
};

export const parsePosition = (positionStr: string) => {
  const [x, y] = positionStr.split(",").map(item => parseInt(item, 10));
  return { x, y };
};
