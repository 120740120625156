import { useMemo } from "react";
import { EntityGetter, HydratedField } from "../types";
import { getAttributesFromFieldsWithSortedFields } from "../utils";

export function useAttributesFromFields(fields: HydratedField[], entityGetter: EntityGetter) {
  return useMemo(
    () => getAttributesFromFieldsWithSortedFields(fields, entityGetter),
    [fields, entityGetter]
  );
}
