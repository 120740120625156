import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { GlobalLoader } from "../../components/Loader";
import Radar from "../../components/Radar";
import { STEP_URLS } from "../../constants";
import { RadarWizardWrapper, RadarWrapper, WizardWrapper } from "./styled";
import Weighting from "./Weighting";
import Welcome from "./Welcome";
import PickFields from "./PickFields";
import PickSubFields from "./PickSubFields";
import PickKeysForSubField from "./PickKeysForSubField";
import { useData } from "./hooks/useData";
import { useHydratedFields } from "./hooks/useHydratedFields";
import {
  useCurrentField,
  useCurrentSubField,
  useNavigateField,
  usePreviewEnabled,
  useRadarTooltipEnabled,
  useTagClickEnabled,
} from "./utils";
import Preview from "./Preview";
import { useFieldErrors } from "./hooks/useFieldErrors";
import Summary from "./Summary";
import { useFieldsActivation } from "./hooks/useFieldsActivation";
import { AuthRouter } from "../../components/ProtectedRoute";
import UserAuth from "./UserAuth";
import { loadUserProfileData } from "../../utils";

function RadarWizard() {
  const isTagClickEnabled = useTagClickEnabled();
  const isRadarTooltipEnabled = useRadarTooltipEnabled();
  const isPreviewEnabled = usePreviewEnabled();

  const {
    dataInitialized,
    fields,
    subFields,
    fieldsMap,
    fieldTypes,
    fieldTypesMap,
    weightByFieldMap,
    availablePresets,
    kpiBySubFieldMap,
    measuresBySubFieldMap,
    matrix,
    connectionLabels,
    subFieldsMap,
    kpis,
    measures,
  } = useData();

  const { fullyActivatedFieldIdsMap } = useFieldsActivation(subFieldsMap, kpis, measures);

  const { hydratedFields, hydratedFieldsMap, hydratedSubFieldsMap } = useHydratedFields(
    fields,
    fieldsMap,
    fieldTypesMap,
    weightByFieldMap,
    subFields,
    kpiBySubFieldMap,
    measuresBySubFieldMap,
    matrix,
    connectionLabels,
    fullyActivatedFieldIdsMap
  );

  const currentField = useCurrentField(hydratedFieldsMap);
  const currentSubField = useCurrentSubField(hydratedFieldsMap);
  const fieldErrors = useFieldErrors(hydratedFields);
  const navigateField = useNavigateField();

  useEffect(() => {
    loadUserProfileData();
  }, []);

  if (!dataInitialized) {
    return <GlobalLoader />;
  }

  function handleTagClicked(fieldId: string) {
    navigateField(fieldId);
  }

  return (
    <RadarWizardWrapper>
      <RadarWrapper>
        <Radar
          isTooltipEnabled={isRadarTooltipEnabled}
          isTagClickEnabled={isTagClickEnabled}
          isPreviewEnabled={isPreviewEnabled}
          fields={hydratedFields}
          fieldsMap={hydratedFieldsMap}
          onTagClicked={handleTagClicked}
          currentField={currentField}
          fieldErrors={fieldErrors}
        />
      </RadarWrapper>
      <WizardWrapper>
        <Routes>
          <Route element={<AuthRouter />}>
            <Route path={STEP_URLS.login} element={<UserAuth />} />
            <Route path={STEP_URLS.signup} element={<UserAuth />} />
            <Route path={STEP_URLS.changePassword} element={<UserAuth />} />
            <Route path={STEP_URLS.start} element={<Welcome />} />
            <Route
              path={STEP_URLS.weighting}
              element={
                <Weighting
                  fields={fields}
                  weightByFieldMap={weightByFieldMap}
                  availablePresets={availablePresets}
                />
              }
            />
            <Route
              path={STEP_URLS.pickFields}
              element={
                <PickFields
                  fieldTypes={fieldTypes}
                  hydratedFields={hydratedFields}
                  onTagClicked={handleTagClicked}
                />
              }
            />
            <Route
              path={STEP_URLS.pickSubFields}
              element={
                <PickSubFields currentField={currentField} hydratedFields={hydratedFields} />
              }
            />
            <Route
              path={STEP_URLS.pickKeysForSubField}
              element={
                <PickKeysForSubField
                  currentField={currentField}
                  currentSubField={currentSubField}
                  subFieldsMap={hydratedSubFieldsMap}
                  fieldsMap={hydratedFieldsMap}
                  hydratedFields={hydratedFields}
                />
              }
            />
            <Route
              path={STEP_URLS.preview}
              element={<Preview fieldErrors={fieldErrors} fields={hydratedFields} />}
            />
            <Route
              path={STEP_URLS.summary}
              element={<Summary availablePresets={availablePresets} />}
            />
          </Route>
        </Routes>
      </WizardWrapper>
    </RadarWizardWrapper>
  );
}

export default RadarWizard;
