import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CustomizationVariables } from "../../@types";
import { lastIntroStepVar } from "../../apollo/state";
import Button from "../../components/Button";
import { GlobalLoader } from "../../components/Loader";
import { INTRO_STEP, STEP_URLS } from "../../constants/routing";
import { getCustomizationParams } from "../../utils";
import { useNavigateWithState } from "../../utils/useNavigateWithState";
import { ButtonsContainer } from "../RadarWizard/styled";
import { GET_INTRO_DATA } from "./graphql";
import {
  Img,
  IntroContent,
  IntroContentWrapper,
  IntroImageWrapper,
  IntroNavigationWrapper,
  IntroWizardWrapper,
  MoreLessButton,
} from "./styled";
import { GetIntroDataResponse } from "./types";
import { useSettings } from "../../context/Settings";
import { LockedInfo } from "../../components/LockedInfo";

function IntroWizard() {
  const customizationParams = getCustomizationParams();
  const navigate = useNavigateWithState();
  const { t } = useTranslation();
  const { demoMode, demoModeText } = useSettings();
  const { introId } = useParams();
  const introStep = Number(introId);
  const [intro, setIntro] = useState({
    leftImage: "",
    introContent: "",
    introDetailContent: "",
  });
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const { data, loading } = useQuery<GetIntroDataResponse, CustomizationVariables>(GET_INTRO_DATA, {
    variables: customizationParams,
  });

  useEffect(() => {
    const currentIntro = data?.intros[introStep - 1];

    if (!introId || (data?.intros && !currentIntro)) {
      navigate(STEP_URLS.intro.replace(INTRO_STEP, "1"));
    }

    if (currentIntro) {
      const hasNextIntro = !data?.intros[introStep];
      const { leftImage, introContent, introDetailContent } = currentIntro;
      setIntro({
        leftImage,
        introContent,
        introDetailContent,
      });
      setIsLast(hasNextIntro);
    }
  }, [data, introStep, introId, navigate]);

  if (loading) {
    return <GlobalLoader />;
  }

  function handleNextClick() {
    setIsFullscreen(false);
    if (isLast) {
      lastIntroStepVar(String(introStep));
      navigate(STEP_URLS.weighting);
    } else {
      navigate(STEP_URLS.intro.replace(INTRO_STEP, String(introStep + 1)));
    }
  }

  function handleBackClick() {
    setIsFullscreen(false);
    if (introStep === 1) {
      navigate(STEP_URLS.start);
    } else {
      navigate(STEP_URLS.intro.replace(INTRO_STEP, String(introStep - 1)));
    }
  }

  return (
    <IntroWizardWrapper>
      <IntroImageWrapper>
        <Img src={intro.leftImage} />
      </IntroImageWrapper>
      <IntroContentWrapper isFullScreen={isFullscreen}>
        <IntroContent
          dangerouslySetInnerHTML={{
            __html: isFullscreen ? intro.introDetailContent : intro.introContent,
          }}
        />
        <IntroNavigationWrapper>
          {intro.introDetailContent && (
            <MoreLessButton onClick={() => setIsFullscreen(!isFullscreen)}>
              {isFullscreen ? t("intro.less", "Show less") : t("intro.more", "More information")}
            </MoreLessButton>
          )}
        </IntroNavigationWrapper>
        <ButtonsContainer>
          <Button onClick={handleBackClick}>{t("wizard.common.back", "BACK")}</Button>
          <Button
            onClick={handleNextClick}
            disabled={isLast && demoMode}
            title={isLast && demoMode ? demoModeText : undefined}
          >
            {isLast ? t("intro.start", "START") : t("common.next", "NEXT")}
          </Button>
        </ButtonsContainer>
        {isLast && demoMode && demoModeText && <LockedInfo>{demoModeText}</LockedInfo>}
      </IntroContentWrapper>
    </IntroWizardWrapper>
  );
}

export default IntroWizard;
