import { useReactiveVar } from "@apollo/client";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { selectedKpisVar } from "../../../apollo/state";
import Box from "../../../components/Box";
import Button from "../../../components/Button";
import Tag from "../../../components/Tag";
import Typography from "../../../components/Typography";
import { STEP_URLS } from "../../../constants";
import { getSelectedElementsCount } from "../../../utils";
import Guide from "../Guide";
import { ButtonsContainer } from "../styled";
import StepWrapper from "../../../components/StepWrapper";
import { HydratedField } from "../types";
import { useNavigateField, hasAllFieldTypeSelected } from "../utils";
import { useNavigateWithState } from "../../../utils/useNavigateWithState";
import { useSettings } from "../../../context/Settings";
import { LockedInfo } from "../../../components/LockedInfo";
import { Accordion, AccordionDetail, AccordionSummary } from "../../../components/Accordion";

type Props = {
  currentField?: HydratedField;
  hydratedFields: HydratedField[];
};

function PickSubFields(props: Props) {
  const { currentField, hydratedFields } = props;
  const { t } = useTranslation();
  const navigate = useNavigateWithState();
  const { lockedFieldText } = useSettings();
  const selectedKPIs = useReactiveVar(selectedKpisVar);
  const navigateField = useNavigateField();
  const navigateSubField = useNavigateField(true);

  const selectedKPIsCount = getSelectedElementsCount(selectedKPIs);
  const isKPIsSelected = selectedKPIsCount > 0;
  const oneFieldPerTypeIsNotSelected = !hasAllFieldTypeSelected(hydratedFields);

  if (!currentField) {
    return null;
  }

  return (
    <StepWrapper>
      <Box sx={{ height: "100%", overflow: "auto" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
          }}
          m="0 0 20px"
        >
          <Typography>{t("wizard.pickSubFields.field", "Field")}</Typography>
          <Tag {...currentField} />
        </Box>
        <Typography lh="20px" m="0 0 20px">
          {currentField.description}
        </Typography>
        <Box m="0 0 50px">
          <Typography bold>
            {t("wizard.pickSubFields.subFields", "Select a sub-field of action")}:
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              gap: "25px 15px",
              marginTop: "20px",
            }}
          >
            {currentField.subFields.map(f => (
              <Fragment key={f.id}>
                <Tag
                  {...f}
                  type={currentField.type}
                  weight={currentField.weight}
                  onClick={() => {
                    navigateSubField(f.id);
                  }}
                />
                <Typography
                  size="14px"
                  dangerouslySetInnerHTML={{
                    __html: f.description,
                  }}
                />
              </Fragment>
            ))}
          </Box>
        </Box>
        <Box m="0 0 20px">
          <Accordion key={`connectedWith-${currentField.id}`}>
            <AccordionSummary>
              <Typography>{t("wizard.pickSubFields.connectedWith", "Connected with")}</Typography>
            </AccordionSummary>
            <AccordionDetail>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "auto 1fr",
                  gap: "25px 15px",
                  marginTop: "20px",
                }}
              >
                {currentField.connectedFields.map(f => (
                  <Fragment key={f.id}>
                    <Tag
                      {...f}
                      onClick={() => {
                        navigateField(f.id);
                      }}
                    />
                    <Typography
                      size="14px"
                      dangerouslySetInnerHTML={{
                        __html: f.connectionLabel,
                      }}
                    />
                  </Fragment>
                ))}
              </Box>
            </AccordionDetail>
          </Accordion>
        </Box>
        {currentField.locked && <LockedInfo>{lockedFieldText}</LockedInfo>}
      </Box>
      <ButtonsContainer>
        <Button
          onClick={() => {
            navigate(STEP_URLS.weighting);
          }}
        >
          {t("wizard.pickSubFields.back", "Back")}
        </Button>
        <Button
          disabled={!isKPIsSelected}
          onClick={() => {
            navigate(STEP_URLS.pickFields);
          }}
        >
          {t("wizard.pickSubFields.next", "Save selection")}
        </Button>
        <Button
          disabled={!isKPIsSelected || oneFieldPerTypeIsNotSelected}
          onClick={() => {
            navigate(STEP_URLS.preview);
          }}
        >
          {t("wizard.pickSubFields.preview", "Preview")}
        </Button>
        {currentField.locked && <LockedInfo>{lockedFieldText}</LockedInfo>}
      </ButtonsContainer>
      <Guide />
    </StepWrapper>
  );
}

export default PickSubFields;
