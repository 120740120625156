import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button";
import { useMutation, useQuery } from "@apollo/client";
import { GET_USER_DATA_BY_TOKEN, SIGNUP_MUTATION } from "../../graphql";
import { useState } from "react";
import { AuthComponentProps } from "../types";
import { getInvitationToken } from "../utils";
import { useNavigateWithState } from "../../../../utils/useNavigateWithState";
import { STEP_URLS } from "../../../../constants";
import { storeUserProfileData } from "../../../../utils";
import { CommonPasswords } from "./CommonPasswords";

export function Signup({ sheetId }: AuthComponentProps) {
  const { t } = useTranslation();
  const navigate = useNavigateWithState();
  const [password, setPassword] = useState("");
  const [isMutationFailed, setIsMutationFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const invitationToken = getInvitationToken();

  const { data } = useQuery(GET_USER_DATA_BY_TOKEN, {
    variables: {
      sheetId,
      invitationToken,
    },
  });

  const [doSignup] = useMutation(SIGNUP_MUTATION, {
    variables: {
      signupArgs: {
        sheetId,
        invitationToken,
        password: password,
      },
    },
  });

  async function handleOKClick() {
    setIsLoading(true);
    try {
      const {
        data: {
          signup: { token, email },
        },
      } = await doSignup();
      storeUserProfileData(email, token);
      setIsMutationFailed(false);
      navigate(STEP_URLS.start);
    } catch (e) {
      setIsMutationFailed(true);
    }
    setIsLoading(false);
  }

  return (
    <CommonPasswords
      userEmail={data?.userDataByToken?.email || ""}
      errorMessage={t("wizard.welcome.signupFailed", "Signup failed")}
      isMutationFailed={isMutationFailed}
      onEnterPressed={handleOKClick}
      onValueChanged={value => {
        setPassword(value);
        setIsMutationFailed(false);
      }}
    >
      {({ shouldDisableControls }) => (
        <Button onClick={handleOKClick} disabled={isLoading || shouldDisableControls}>
          {t("wizard.welcome.authOK", "OK")}
        </Button>
      )}
    </CommonPasswords>
  );
}
