import { useTranslation } from "react-i18next";
import { useReactiveVar } from "@apollo/client";
import { userEmailVar } from "../../../../apollo/state";
import { ChangePasswordLink } from "../styled";
import { useNavigateWithState } from "../../../../utils/useNavigateWithState";
import { STEP_URLS } from "../../../../constants";
import { useUrlMatching } from "../../utils";
import { removeUserProfileData } from "../../../../utils";

export function LoggedInUserInfo() {
  const { t } = useTranslation();
  const userEmail = useReactiveVar(userEmailVar);
  const navigate = useNavigateWithState();
  const isChangePasswordPage = useUrlMatching([STEP_URLS.changePassword]);
  const isSignupPage = useUrlMatching([STEP_URLS.signup]);

  if (isSignupPage || !userEmail) {
    return null;
  }

  return (
    <div>
      {t("wizard.welcome.loggedInAs", "Logged in as")}
      &nbsp;
      <strong>{userEmail}</strong>
      {!isChangePasswordPage && (
        <ChangePasswordLink onClick={() => navigate(STEP_URLS.changePassword)}>
          {t("wizard.welcome.changePassword", "Change password")}
        </ChangePasswordLink>
      )}
      <ChangePasswordLink onClick={() => removeUserProfileData()}>
        {t("wizard.welcome.logout", "Logout")}
      </ChangePasswordLink>
    </div>
  );
}
