import { gql } from "@apollo/client";

export const GET_FIELDS_DATA = gql`
  query getFieldsData($sheetId: String, $localeId: String) {
    fields(sheetId: $sheetId, localeId: $localeId) {
      id: number
      name
      typeId: type
      quadrant
      position
      description
      locked
    }

    subFields(sheetId: $sheetId, localeId: $localeId) {
      id: number
      name
      description
      area
      locked
    }

    fieldTypes(sheetId: $sheetId, localeId: $localeId) {
      id: number
      name
      baseColor
    }

    weights(sheetId: $sheetId, localeId: $localeId) {
      field
      presets {
        id
        key
        name
        value
        isDefault
      }
    }

    kpis(sheetId: $sheetId, localeId: $localeId) {
      id: number
      subFields
      shortName
      name
      description
      default: defaultPreset
      preset1
      preset2
      preset3
      preset4
      preset5
      preset6
      preset7
      preset9
      preset9
    }

    measures(sheetId: $sheetId, localeId: $localeId) {
      id: number
      subFields
      shortName
      name
      description
      default: defaultPreset
      preset1
      preset2
      preset3
      preset4
      preset5
      preset6
      preset7
      preset9
      preset9
    }

    shortMatrix(sheetId: $sheetId, localeId: $localeId) {
      values
    }

    longMatrix(sheetId: $sheetId, localeId: $localeId) {
      values
    }

    matrixLabels(sheetId: $sheetId, localeId: $localeId) {
      values
    }
  }
`;

export const GET_USER_DATA_BY_TOKEN = gql`
  query GetUserDataByToken($sheetId: String, $invitationToken: String!) {
    userDataByToken(sheetId: $sheetId, invitationToken: $invitationToken) {
      token
      email
    }
  }
`;

export const SIGNIN_MUTATION = gql`
  mutation SigninMutation($signinArgs: SigninArgs!) {
    signin(signinArgs: $signinArgs) {
      token
      email
    }
  }
`;

export const SIGNUP_MUTATION = gql`
  mutation SignupMutation($signupArgs: SignupArgs!) {
    signup(signupArgs: $signupArgs) {
      token
      email
    }
  }
`;

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePasswordMutation($updatePasswordArgs: UpdatePasswordArgs!) {
    updatePassword(updatePasswordArgs: $updatePasswordArgs) {
      token
      email
    }
  }
`;
