export function PdfIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlSpace="preserve">
      <path d="M47.987 21.938a.99.99 0 0 0-.053-.264c-.011-.032-.019-.063-.033-.094a.957.957 0 0 0-.193-.285l-.001-.001L42 15.586V10c0-.022-.011-.041-.013-.063a1.028 1.028 0 0 0-.051-.257c-.011-.032-.019-.063-.034-.094a.997.997 0 0 0-.196-.293l-9-9a.992.992 0 0 0-.294-.197c-.03-.013-.06-.022-.09-.032a.998.998 0 0 0-.264-.053C32.038.01 32.02 0 32 0H7a1 1 0 0 0-1 1v14.586L.293 21.293l-.002.002a.98.98 0 0 0-.192.285c-.014.031-.022.062-.033.094a.953.953 0 0 0-.053.264C.011 21.96 0 21.978 0 22v19a1 1 0 0 0 1 1h5v5a1 1 0 0 0 1 1h34a1 1 0 0 0 1-1v-5h5a1 1 0 0 0 1-1V22c0-.022-.011-.04-.013-.062zM44.586 21H42v-2.586L44.586 21zm-6-12H33V3.414L38.586 9zM8 2h23v8a1 1 0 0 0 1 1h8v10H8V2zM6 18.414V21H3.414L6 18.414zM40 46H8v-4h32v4zm6-6H2V23h44v17z" />
      <path d="M18.254 26.72a2.82 2.82 0 0 0-1.097-.586 4.453 4.453 0 0 0-1.19-.17h-3.332V38h2.006v-4.828h1.428c.419 0 .827-.074 1.224-.221a2.9 2.9 0 0 0 1.054-.68c.306-.306.552-.688.74-1.148.187-.459.281-.994.281-1.606 0-.68-.105-1.247-.315-1.7a3.19 3.19 0 0 0-.799-1.097zm-1.283 4.285c-.306.334-.697.501-1.173.501h-1.156v-3.825h1.156c.476 0 .867.147 1.173.442.306.295.459.765.459 1.411s-.153 1.136-.459 1.471zM30.723 38h2.057v-5.168h3.077v-1.751h-3.093V27.8h3.348v-1.836h-5.389zM24.076 25.964H21.05V38h3.009c1.553 0 2.729-.524 3.528-1.572.799-1.049 1.198-2.525 1.198-4.429 0-1.904-.399-3.386-1.198-4.446-.799-1.059-1.969-1.589-3.511-1.589zm2.474 7.879c-.13.528-.315.967-.552 1.318a2.385 2.385 0 0 1-.85.79 2.235 2.235 0 0 1-1.071.264h-.969v-8.466h.969c.385 0 .742.088 1.071.264.329.175.612.439.85.79.238.351.422.793.552 1.326s.196 1.156.196 1.87c0 .703-.066 1.317-.196 1.844z" />
    </svg>
  );
}

export function XlsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlSpace="preserve">
      <path d="M47.988 21.94a.976.976 0 0 0-.054-.266c-.011-.032-.019-.063-.033-.094a.988.988 0 0 0-.195-.288L42 15.586V10c0-.021-.011-.04-.012-.061a.993.993 0 0 0-.052-.259c-.011-.032-.019-.062-.033-.093a.997.997 0 0 0-.196-.293l-9-9a1.004 1.004 0 0 0-.291-.195c-.032-.015-.065-.024-.099-.035a1.01 1.01 0 0 0-.249-.05C32.044.012 32.023 0 32 0H7a1 1 0 0 0-1 1v14.586L.294 21.292l-.004.005a.974.974 0 0 0-.191.283c-.014.03-.022.062-.033.094a.998.998 0 0 0-.054.266C.011 21.961 0 21.979 0 22v19a1 1 0 0 0 1 1h5v5a1 1 0 0 0 1 1h34a1 1 0 0 0 1-1v-5h5a1 1 0 0 0 1-1V22c0-.021-.011-.039-.012-.06zM44.586 21H42v-2.586L44.586 21zm-6-12H33V3.414L38.586 9zM8 2h23v8a1 1 0 0 0 1 1h8v10H8V2zM6 18.414V21H3.414L6 18.414zM40 46H8v-4h32v4zm6-6H2V23h44v17z" />
      <path d="M24.374 25.964h-2.057V38h5.372v-1.887h-3.315zM31.021 27.885c.147-.136.312-.235.493-.298a1.72 1.72 0 0 1 .561-.093c.669 0 1.224.266 1.666.799l1.122-1.462a3.164 3.164 0 0 0-1.215-.876c-.482-.198-1.028-.297-1.64-.297-.419 0-.833.071-1.241.212-.408.142-.774.36-1.097.655a3.285 3.285 0 0 0-.782 1.113c-.198.448-.298.984-.298 1.607 0 .499.065.926.195 1.283.13.358.306.669.527.935.221.267.476.496.765.689.289.193.598.368.927.527.521.261.952.544 1.292.85.34.306.51.72.51 1.241 0 .533-.142.946-.425 1.241a1.42 1.42 0 0 1-1.071.442c-.385 0-.762-.091-1.131-.272s-.683-.431-.944-.748l-1.105 1.496c.34.397.793.725 1.36.986a4.375 4.375 0 0 0 1.853.391c.465 0 .907-.079 1.326-.238a3.076 3.076 0 0 0 1.097-.706c.312-.311.561-.694.748-1.147s.28-.975.28-1.564c0-.51-.079-.952-.238-1.326a3.376 3.376 0 0 0-.612-.969 4.065 4.065 0 0 0-.833-.697 10.324 10.324 0 0 0-.901-.51c-.499-.249-.901-.513-1.207-.791-.306-.277-.459-.671-.459-1.181 0-.295.042-.55.128-.765.085-.215.202-.391.349-.527zM20.719 25.964h-2.091l-1.768 4.148-1.785-4.148H12.95l2.72 5.695L12.661 38h2.125l2.023-4.607L18.815 38h2.193l-3.026-6.341z" />
    </svg>
  );
}
