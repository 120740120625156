import { HydratedField } from "../types";
import { GroupedFields } from "./types";
import { orderBy } from "lodash-es";

export function getPickFields(fields: HydratedField[]) {
  return orderBy(fields, ["weight", "name"], ["desc", "asc"]).reduce<GroupedFields>((acc, item) => {
    acc[item.type.id] = acc[item.type.id] || [];
    acc[item.type.id].push(item);

    return acc;
  }, {});
}
