import { useReactiveVar } from "@apollo/client";
import { ChangeEvent, Fragment, MouseEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { sortBy } from "lodash-es";
import striptags from "striptags";
import { lastIntroStepVar, selectedPresetNameVar, selectedPresetVar } from "../../../apollo/state";
import Box from "../../../components/Box";
import Button from "../../../components/Button";
import RadioGroup from "../../../components/RadioGroup";
import SectionTitle from "../../../components/SectionTitle";
import Select from "../../../components/Select";
import Typography from "../../../components/Typography";
import WeightIndicator from "../../../components/WeightIndicator";
import { STEP_URLS } from "../../../constants";
import { INTRO_STEP } from "../../../constants/routing";
import { useNavigateWithState } from "../../../utils/useNavigateWithState";
import { ButtonsContainer } from "../styled";
import StepWrapper from "../../../components/StepWrapper";
import RadioLabel from "./RadioLabel";
import { WeightingProps } from "./types";

function Weighting({ fields, availablePresets, weightByFieldMap }: WeightingProps) {
  const { t } = useTranslation();
  const navigate = useNavigateWithState();
  const selectedPreset = useReactiveVar(selectedPresetVar);
  const lastIntroStep = useReactiveVar(lastIntroStepVar);
  const presetRbRef = useRef<HTMLInputElement>(null);

  const sortedFields = sortBy(fields, "typeId");
  const standardPresetKey = availablePresets.standard.key;

  function handlePresetSelection(preset: string) {
    selectedPresetVar(preset);
    selectedPresetNameVar(
      preset === standardPresetKey
        ? availablePresets.standard.name
        : availablePresets.custom.find(p => p.key === preset)?.name || ""
    );
  }

  function handleNextClick() {
    navigate(STEP_URLS.pickFields);
  }

  function onSelectMouseUp() {
    if (selectedPreset === standardPresetKey) {
      presetRbRef.current!.click();
    }
  }

  function onSelectMouseDown(e: MouseEvent<HTMLInputElement>) {
    if (selectedPreset === standardPresetKey) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  function handleBackClick() {
    navigate(lastIntroStep ? STEP_URLS.intro.replace(INTRO_STEP, lastIntroStep) : STEP_URLS.start);
  }

  return (
    <StepWrapper hidePreset>
      <SectionTitle>
        {t("wizard.weighting.title", "weighting of the fields of action")}
      </SectionTitle>
      <Box
        m="0 0 40px"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          "@media (max-width: 1365px)": {
            flexWrap: "wrap",
          },
        }}
      >
        <RadioGroup
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handlePresetSelection(e.target.value);
          }}
        >
          <RadioLabel
            name="selectedPreset"
            value={standardPresetKey}
            defaultChecked={selectedPreset === standardPresetKey}
            title={availablePresets.standard.name}
            subtitle={t("wizard.weighting.weightedEqually", "weighted equally")}
          />
          <Typography>{t("wizard.weighting.or", "or")}</Typography>
          <RadioLabel
            ref={presetRbRef}
            name="selectedPreset"
            value={availablePresets.custom[0].key}
            defaultChecked={selectedPreset !== standardPresetKey}
            title={t("wizard.weighting.sample", "Sample")}
            subtitle={t("wizard.weighting.choose", "choose")}
          />
        </RadioGroup>
        <Select
          value={selectedPreset}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            handlePresetSelection(e.currentTarget.value)
          }
          onMouseDown={onSelectMouseDown}
          onMouseUp={onSelectMouseUp}
        >
          {selectedPreset !== standardPresetKey &&
            availablePresets.custom.map(p => (
              <option key={p.key} value={p.key}>
                {p.name}
              </option>
            ))}
        </Select>
      </Box>
      <Box
        sx={{
          display: "grid",
          alignItems: "center",
          rowGap: "15px",
          gridTemplateColumns: "40% 58%",
          overflow: "auto",
          height: "100%",

          "& > div:nth-child(-n+2)": {
            marginBottom: "10px",
          },
        }}
      >
        <Typography color="secondary-text-color">
          {t("wizard.weighting.fieldOfAction", "Field of action")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography color="secondary-text-color" size="12px">
            {t("wizard.weighting.low", "Low")}
          </Typography>
          <Typography color="secondary-text-color">
            {t("wizard.weighting.importance", "importance")}
          </Typography>
          <Typography color="secondary-text-color" size="12px">
            {t("wizard.weighting.high", "High")}
          </Typography>
        </Box>
        {sortedFields.map(f => {
          const preset = weightByFieldMap[f.id][selectedPreset];
          return (
            <Fragment key={f.id}>
              <Typography size="16px">{striptags(f.name, [], " ")}</Typography>
              <WeightIndicator value={preset.value * 100} />
            </Fragment>
          );
        })}
      </Box>
      <ButtonsContainer>
        <Button onClick={handleBackClick}>{t("wizard.common.back", "Back")}</Button>
        <Button onClick={handleNextClick}>{t("wizard.common.next", "Next")}</Button>
      </ButtonsContainer>
    </StepWrapper>
  );
}

export default Weighting;
