export function ExclamationIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={21} height={21}>
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "var(--icon_exclamation)",
          fillOpacity: 1,
        }}
        d="M10.5.21C4.816.21.21 4.817.21 10.5c0 5.684 4.606 10.29 10.29 10.29 5.684 0 10.29-4.606 10.29-10.29C20.79 4.816 16.183.21 10.5.21Zm0 18.935a8.646 8.646 0 0 1 0-17.29 8.646 8.646 0 0 1 0 17.29Zm0 0"
      />
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "var(--icon_exclamation)",
          fillOpacity: 1,
        }}
        d="M9.676 4.875h1.648v8.23H9.676ZM9.66 14.48h1.649v1.645H9.66Zm0 0"
      />
    </svg>
  );
}

export function SimpleInfoIcon() {
  return (
    <svg width="14.32px" height="30.72px" viewBox="0 0 57.288 122.88">
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.499,0c4.436,0,7.926,1.281,10.468,3.819c2.515,2.53,3.785,6.007,3.785,10.481 c0,4.538-2.163,8.572-6.521,12.11c-4.381,3.538-9.463,5.318-15.254,5.318c-4.352,0-7.812-1.218-10.465-3.66 c-2.651-2.47-3.976-5.665-3.976-9.639c0-5.007,2.163-9.322,6.465-12.987C21.301,1.816,26.465,0,32.499,0L32.499,0z M57.288,122.88 H0v-9.64h10.765V54.36H0v-7.674h26.775c6.763,0,13.418-0.812,19.992-2.47v69.024h10.521V122.88L57.288,122.88z"
        />
      </g>
    </svg>
  );
}
