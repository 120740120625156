import { Trans, useTranslation } from "react-i18next";
import Box from "../../../../components/Box";
import StepWrapper from "../../../../components/StepWrapper";
import Typography from "../../../../components/Typography";
import {
  FunkDescription,
  FunkFoundationWrapper,
  FunkLogo,
  FunkText,
  UnderlinedButton,
} from "../styled";
import funkLogo from "../fs-logo.png";
import HexagonIcon from "../HexagonIcon";
import { useConfigurableStyles } from "../../../../context/ConfigurableStyles";
import { ReactNode } from "react";
import { LoggedInUserInfo } from "./LoggedInUserInfo";

function LoginWelcome({ children, hidePreset }: { children: ReactNode; hidePreset: boolean }) {
  const { t } = useTranslation();
  const styles = useConfigurableStyles();

  function handleLoadFunkPage() {
    window.open("https://www.funk-stiftung.org/de/", "_blank")?.focus();
  }

  const digitalText = t("wizard.welcome.digitalText"); // Development and operation of this application are supported by the
  const digitalLink = t("wizard.welcome.digitalLink"); // Funk Foundation

  return (
    <StepWrapper sx={{ width: "70%" }} hidePreset={hidePreset}>
      {(digitalText || digitalLink) && (
        <FunkFoundationWrapper>
          <FunkText>
            <FunkDescription>{digitalText}</FunkDescription>
            {!styles.welcomeLogoUrl ? (
              <UnderlinedButton onClick={handleLoadFunkPage}>{digitalLink}</UnderlinedButton>
            ) : (
              <FunkDescription>{digitalLink}</FunkDescription>
            )}
          </FunkText>
          {!styles.welcomeLogoUrl && (
            <FunkLogo src={funkLogo} alt="Digital Quick Check" onClick={handleLoadFunkPage} />
          )}
        </FunkFoundationWrapper>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          gap: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {styles.welcomeLogoUrl && (
            <Box as="img" src={styles.welcomeLogoUrl} alt="Logo" sx={{ maxWidth: "320px" }} />
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Box>
              <Typography
                size="38px"
                uppercase
                ls="1.2px"
                color="headline-color"
                font="headline-text-font-type"
              >
                {t("wizard.welcome.title", "Welcome to")}
              </Typography>
              <Typography
                size="70px"
                bold
                uppercase
                ls="2.4px"
                color="headline-color"
                font="headline-text-font-type"
                sx={{ marginBottom: "55px" }}
              >
                {t("wizard.welcome.subTitle", "ESG radar")}
              </Typography>
              <Typography size="21px" ls="0.75px" m="0 0 25px">
                <Trans i18nKey="wizard.welcome.description">
                  Finden Sie in wenigen Schritten heraus, <br />
                  wie Sie Ihr Unternehmen nachhaltiger
                  <br /> <strong>planen</strong> und <strong>gestalten</strong> können.
                </Trans>
              </Typography>
              <LoggedInUserInfo />
            </Box>
            {children}
          </Box>
        </Box>
        {!styles.welcomeLogoUrl && (
          <HexagonIcon
            m="0 0 0 auto"
            sx={{
              maxWidth: "100%",
              height: "auto",
              display: "none",
              "@media (min-width: 1024px)": {
                display: "block",
              },
            }}
          />
        )}
      </Box>
    </StepWrapper>
  );
}

export default LoginWelcome;
